import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { ReceiverApproverModel, ReceiverModel } from '../api/rtk/receiverApi'
import { InboundInvoiceModel } from '../api/rtk/types/inboundInvoiceApi'
import {
  getFilterItemsByFieldSelector,
  getFindItemByIdSelector,
  getSubentitySelectors
} from '../helpers/typedSelectorHelpers'
import { RequiredFields } from '../helpers/typeHelpers'
import { RootState } from '../reducerTypes'
import { findInboundInvoiceWithId } from './inboundInvoiceSelectors'

export const getReceiversFromArgument = (arg: RootState | ReceiverModel[]) => Array.isArray(arg) ? arg : arg.receivers.records
const getReceiverApproversFromArgument = (arg: RootState | ReceiverApproverModel[]) => Array.isArray(arg) ? arg : arg.receivers.approvers.records

export const findReceiverWithId = getFindItemByIdSelector(getReceiversFromArgument)

export const {
  getSubItemsOfItem: getFilesOfReceiver
} = getSubentitySelectors(state => state.receivers.files.records, state => state.files.records, 'receiverId', 'fileId')

export const getPaymentProhibitedReceivers = createSelector(
  getReceiversFromArgument,
  receivers => receivers.filter(receiver => receiver.isPaymentProhibited)
)

export const getReceiverApproversByReceiver = getFilterItemsByFieldSelector(getReceiverApproversFromArgument, ['receiverId'])

export const findReceiverByInboundInvoiceId = createCachedSelector(
  [
    (state: RootState, { inboundInvoiceId }: { inboundInvoiceId?: number | null }) => getReceiversFromArgument(state),
    // @ts-expect-error not typed selector
    (state, { inboundInvoiceId }) => findInboundInvoiceWithId(state, inboundInvoiceId)
  ],
  (receivers, inboundInvoice?: InboundInvoiceModel) => inboundInvoice?.receiverId == null ? null : receivers.find(receiver => receiver.id === inboundInvoice.receiverId)
)((state, { inboundInvoiceId }) => `${inboundInvoiceId}`)

export const getReceiversWithSupplierId = createSelector(
  getReceiversFromArgument,
  receivers => receivers.filter(({ supplierId }) => supplierId != null) as RequiredFields<ReceiverModel, 'supplierId'>[]
)
