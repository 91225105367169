import { toIdMap } from '@evelia/common/helpers'
import { createSelector } from 'reselect'

import { getFilterItemsByFieldSelector, getFindItemByIdSelector } from '../helpers/selectorHelpers'
import { findCurrentEmployee } from './employeeSelectors'

export const getAuthorsFromArgument = arg => arg.authors ? arg.authors.records : arg

export const findAuthorWithId = getFindItemByIdSelector(getAuthorsFromArgument)

export const findAuthorsWithEmployeeId = getFilterItemsByFieldSelector(getAuthorsFromArgument, 'employeeId')

export const findAuthorsOfCurrentEmployee = createSelector(
  getAuthorsFromArgument,
  findCurrentEmployee,
  (authors, currentEmployee) => findAuthorsWithEmployeeId(authors, currentEmployee.id)
)

export const getAuthorsMap = createSelector(
  getAuthorsFromArgument,
  authors => toIdMap(authors))
