import { SalaryCalculationModel } from '@evelia/common/types'
import {
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit'

import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState, RootState } from '../reducerTypes'
import getFileLinkSlice from './getFileLinkSlice'

const salaryCalculationAdapter = createEntityAdapter<SalaryCalculationModel>({})

const salaryCalculationSlice = createGenericSlice({
  name: 'salaryCalculations',
  entityAdapter: salaryCalculationAdapter,
  reducers: {
    doPatchAction: (state, __action: PayloadAction<{ record: Partial<SalaryCalculationModel> }>) => state
  }
})

export const salaryCalculationEntitySelectors = salaryCalculationAdapter.getSelectors<EveliaRootState>(state => state.salaryPeriods.salaryCalculations)
export const salaryCalculationActions = salaryCalculationSlice.actions

export const salaryCalculationFileSlice = getFileLinkSlice('salaryCalculationFiles', (state: RootState) => state.salaryPeriods.salaryCalculationFiles)

export default salaryCalculationSlice
