import constants, { actionTypes } from '../constants'
import { actionCreatorsFor, request } from '../helpers/actionHelpers'
import tableActions from './tableActions'

const baseActionCreators = actionCreatorsFor(constants.actionKeys.TIME_RECORDS, {})

const additionalActionCreators = {
  timeRecordTypes: {
    ...actionCreatorsFor(constants.actionKeys.TIME_RECORD_SYSTEM_TYPES),
    doPostAction: request(actionTypes.TIME_RECORD_SYSTEM_TYPE_POST_ACTION_REQUEST)
  },
  timeRecordSites: actionCreatorsFor(constants.actionKeys.TIME_RECORD_SITES),
  timeRecordAccumulations: {
    ...actionCreatorsFor(constants.actionKeys.TIME_RECORD_ACCUMULATIONS, { fetchStats: true }, '_id'),
    doTimeRecordAccumulationUpdateAction: request(actionTypes.TIME_RECORD_ACCUMULATION_UPDATE_ACTION_REQUEST)
  },
  tableActions: tableActions(constants.actionKeys.TIME_RECORDS)
}

const timeRecordActions = { ...baseActionCreators, ...additionalActionCreators }
export default timeRecordActions
