import { timeRecordAccumulationTypes } from '@evelia/common/constants'
import { now } from '@evelia/common/dateHelpers'

import timeRecordActions from '../actions/timeRecordActions'
import constants from '../constants'
import { getCombinedListReducerFor, getTableOptions } from '../helpers/reducerHelpers'
import tableReducerFor from './tableReducer'

export const initialTimeRecordTableOptions = {
  default: getTableOptions({ orderBy: ['startedAt'], sortOrder: ['DESC'] }),
  supervisor: getTableOptions({ orderBy: ['startedAt'], sortOrder: ['DESC'] })
}

const initialTimeRecordAccumulationStats = {
  [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_FLEXITIME]: 0,
  [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_OVERTIME]: 0
}

const initialStatsState = {
  records: {},
  isBusy: false
}

export default getCombinedListReducerFor(constants.actionKeys.TIME_RECORDS, {
  timeRecordTypes: getCombinedListReducerFor(constants.actionKeys.TIME_RECORD_SYSTEM_TYPES),
  timeRecordSites: getCombinedListReducerFor(constants.actionKeys.TIME_RECORD_SITES),
  timeRecordAccumulations: getCombinedListReducerFor(constants.actionKeys.TIME_RECORD_ACCUMULATIONS, {
    stats: (state = initialStatsState, action) => {
      switch(action.type) {
        case timeRecordActions.timeRecordAccumulations.actionTypes.fetchStatsStart:
          return {
            ...state,
            isBusy: true
          }
        case timeRecordActions.timeRecordAccumulations.actionTypes.fetchStatsSuccess:
          return {
            ...state,
            records: {
              ...state.records,
              [action.record.id]: {
                ...initialTimeRecordAccumulationStats,
                ...action.record,
                updatedAt: now()
              }
            },
            isBusy: false
          }
        case timeRecordActions.timeRecordAccumulations.actionTypes.fetchStatsError:
          return {
            ...state,
            isBusy: false
          }
        default:
          return state
      }
    }
  }, '_id'),
  tableOptions: tableReducerFor(constants.actionKeys.TIME_RECORDS, initialTimeRecordTableOptions)
})
