import { BaseIdModel } from '@evelia/common/types'

import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'

export type OccupationClassificationModel = {
  code: string
  name: string
  description: string
} & BaseIdModel

export const occupationClassificationApi = createApi({
  base: 'occupation_classifications',
  normalizer: defaultEmbeddedNormalizer
})
