import { InsuranceModel } from '@evelia/common/types'
import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const insuranceAdapter = createEntityAdapter<InsuranceModel>({})

const insuranceSlice = createGenericSlice({
  name: 'insurances',
  entityAdapter: insuranceAdapter
})

export const insuranceEntitySelectors = insuranceAdapter.getSelectors<EveliaRootState>(state => state.insurances)
export const insuranceActions = insuranceSlice.actions

export default insuranceSlice
