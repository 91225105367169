import {
  dateFormats,
  formatDate,
  formatDateOrEmpty,
  formatDateTime,
  formatTimeOrEmpty,
  moment,
  withFormat
} from '@evelia/common/dateHelpers'
import { appendEitherOrEmpty, round3decimals } from '@evelia/common/helpers'

export const formatDateFromSeparateFields = (date, time) => appendEitherOrEmpty(formatDateOrEmpty(date), formatTimeOrEmpty(time, dateFormats.finnishTimeFormatShort)) || '-'

export const formatDateTimeRange = (start, end, sameDayFormat = dateFormats.finnishTimeFormat, differentDayFormat = dateFormats.finnishDateTimeFormat, separator = ' - ') => {
  const startMoment = moment(start)
  const endMoment = moment(end)
  const endMomentFormatted = formatDateTime(endMoment, startMoment.isSame(endMoment, 'day') ? sameDayFormat : differentDayFormat)
  return `${formatDateTime(startMoment, differentDayFormat)}${separator}${endMomentFormatted} `
}

export const formatDateRange = (start, end, dayFormat = 'ddd DD.', monthFormat = 'MM.', yearFormat = 'YYYY') => {
  const startMoment = moment(start)
  const endMoment = moment(end)
  const dayAndMonthFormat = dayFormat + monthFormat
  const fullFormat = dayAndMonthFormat + yearFormat
  if(startMoment.year() === endMoment.year()) {
    if(startMoment.month() === endMoment.month()) {
      if(startMoment.date() === endMoment.date()) {
        return startMoment.format(fullFormat)
      } else {
        return `${startMoment.format(dayFormat)} - ${endMoment.format(fullFormat)} `
      }
    } else {
      return `${startMoment.format(dayAndMonthFormat)} - ${endMoment.format(fullFormat)} `
    }
  } else {
    return `${startMoment.format(fullFormat)} - ${endMoment.format(fullFormat)} `
  }
}

export const getDateTimeFromDateAndTime = (date, time, includeSeconds) => {
  const dateMoment = moment(date)
  if(time) {
    const timeMoment = moment(time)
    if(timeMoment.isValid()) {
      dateMoment
        .hour(timeMoment.hour())
        .minute(timeMoment.minute())
        .second(includeSeconds ? timeMoment.second() : 0)
    }
  }
  return dateMoment
}

export const formatConditionalStartEndDateTime = ({ startDate, endDate, startTime, endTime }) => {
  const displayEndTime = !!endTime
  const displayEndDate = moment(endDate).isValid() && startDate !== endDate
  const endDateFormat = displayEndDate && moment(startDate).year() === moment(endDate).year() ? dateFormats.finnishDateFormatShort : dateFormats.finnishDateFormat

  const startFormat = endTime && startTime ? dateFormats.finnishDateTimeFormatShort : dateFormats.finnishDateFormat
  const startDateTime = withFormat(getDateTimeFromDateAndTime(startDate, startTime), startFormat)
  const endDateString = displayEndDate ? formatDate(endDate, endDateFormat) : ''
  const endTimeString = displayEndTime ? withFormat(endTime, dateFormats.finnishTimeFormatShort) : ''
  return appendEitherOrEmpty(startDateTime, appendEitherOrEmpty(endDateString, endTimeString), ' - ')
}

export const calculateDuration = (model, unitOfTime) =>
  model.startDate && model.startTime && model.endTime && model.endDate
    ? round3decimals(getDateTimeFromDateAndTime(model.endDate, model.endTime).diff(getDateTimeFromDateAndTime(model.startDate, model.startTime), unitOfTime || 'hours', true))
    : null

export const formatHoursAndMinutesDuration = (value, unitOfTime) => {
  const duration = moment.duration(value, unitOfTime)
  const dayCount = duration.days()
  const days = dayCount ? `${Math.abs(duration.days())} pv ` : ''
  const hours = Math.abs(duration.hours())
  const minutes = Math.abs(duration.minutes())
  return value === 0 ? '-' : `${value < 0 ? '-' : ''}${days}${hours} h ${minutes} min`
}
