import { createRef, PureComponent } from 'react'
import DateMomentUtils from '@date-io/moment'
import { dateFormats, moment, now } from '@evelia/common/dateHelpers'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import { keyCodes } from '../../../constants'
import { checkIfEnter } from '../../../helpers/helpers'
import MaterialUiInputAdapter from '../helpers/MaterialUiInputAdapter'
import { commonFormInputDefaultProps, commonFormInputPropTypes } from '../propTypes'

class TimeFormInput extends PureComponent {
  constructor(props) {
    super(props)
    this.state.value = this.formatValue(props.value)
    this.inputRef = createRef()
  }

  state = {
    isOpen: false,
    lastSelectionStart: null,
    lastSelectionEnd: null,
    value: null
  }

  componentDidUpdate = (prevProps, prevState) => {
    // Retain cursor position when model updates
    if(prevProps.value !== this.props.value) {
      const { lastSelectionStart, lastSelectionEnd } = this.state
      this.inputRef.type !== 'hidden' && this.inputRef === document.activeElement && this.inputRef.setSelectionRange(lastSelectionStart, lastSelectionEnd || lastSelectionStart)
    }
    if(prevState.value !== this.state.value) {
      this.handleModelChange()
    } else if(prevProps.value !== this.props.value) {
      this.setState({ value: this.formatValue(this.props.value) }) // eslint-disable-line react/no-did-update-set-state
    }
  }

  setOpen = () => this.setIsOpen(true)
  setClosed = () => this.setIsOpen(false)
  setIsOpen = open => this.setState({ isOpen: open })
  setNow = () => this.handleOnChange(now())
  clear = () => this.handleOnChange(null)

  getValueForPicker = () => this.state.value ? moment(this.state.value, [moment.ISO_8601, dateFormats.isoTimeFormat]).format() : null

  render() {
    const {
      isInvalid,
      disabled,
      disableAll,
      readOnly,
      connected,
      field
    } = this.props

    const { value } = this.state

    return (
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <KeyboardTimePicker
          value={this.getValueForPicker()}
          invalid={isInvalid}
          onChange={this.handleOnChange}
          TextFieldComponent={MaterialUiInputAdapter}
          ampm={false}
          format='HH:mm'
          disabled={disabled || disableAll || readOnly || (connected && !value)}
          readOnly={readOnly}
          clearable
          autoOk
          clearLabel='Tyhjennä'
          cancelLabel='Peruuta'
          // showTodayButton
          todayLabel='Tänään'
          onError={this.handleError}
          open={this.state.isOpen}
          onOpen={this.setOpen}
          onClose={this.setClosed}
          setOpen={this.setOpen}
          setNow={this.setNow}
          clear={this.clear}
          onKeyUp={this.handleKeyUp}
          onBlur={this.onBlur}
          inputRef={this.inputRef}
          invalidDateMessage='Virheellinen muoto'
          data-testid={field}
        />
      </MuiPickersUtilsProvider>
    )
  }

  onBlur = () => {
    this.setState({ value: this.formatValue(this.props.value, true) })
  }

  formatValue = (value, blur) => {
    if(value == null) {
      return value
    }
    const { timeOnly } = this.props
    const momentValue = value instanceof moment ? value : moment(value, [moment.ISO_8601, dateFormats.isoTimeFormat])
    const format = timeOnly ? dateFormats.isoTimeFormat : dateFormats.isoDateTimeFormat
    return value ? momentValue.format(format) : value
  }

  handleOnChange = date => {
    this.setState({ lastSelectionStart: this.inputRef.selectionStart, lastSelectionEnd: this.inputRef.selectionEnd, value: this.formatValue(date) })
  }

  handleModelChange = () => {
    const {
      onChange,
      setNull
    } = this.props
    let { value } = this.state
    const { connected } = this.props
    if(value) {
      const momentValue = moment(value, [moment.ISO_8601, dateFormats.isoTimeFormat])
      if(!momentValue.isValid()) {
        this.setValidationError('Virheellinen kellonaika')
        return
      }
      if(connected) {
        value = this.alterTime(momentValue)
      } else {
        value = this.formatValue(momentValue)
      }
    } else if(connected) {
      value = this.alterTime(null)
    } else if(setNull) {
      value = null
    }
    this.clearValidationErrors()
    return onChange(value, null)
  }

  alterTime = momentValue => {
    const { value } = this.props
    if(!value) {
      return value
    }
    const propValue = moment(value || undefined, [moment.ISO_8601, dateFormats.isoTimeFormat])
    return this.formatValue(momentValue ? propValue.hour(momentValue.hour()).minute(momentValue.minute()).second(momentValue.second()) : propValue.startOf('day'))
  }

  handleKeyUp = event => {
    if(event.keyCode === keyCodes.ESC) {
      return this.props.onEscPressed(event)
    } else if(checkIfEnter(event)) {
      return this.props.onEnterPressed(event)
    }
    return event
  }

  handleError = msg => this.setValidationError(msg)

  setValidationError = msg => this.props.setValidationError?.(msg)
  clearValidationErrors = () => this.props.clearValidationError?.()
  static propTypes = commonFormInputPropTypes
  static defaultProps = {
    ...commonFormInputDefaultProps
  }
}

export default TimeFormInput
