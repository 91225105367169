import { NullableId, SalaryCalculationModel, SalaryCalculationRowModel } from '@evelia/common/types'

import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'

export type SalaryCalculationRowBaseModel = NullableId<SalaryCalculationRowModel>

export const defaultSalaryCalculationRowModel: SalaryCalculationRowBaseModel = {
  id: null,
  salaryCalculationId: 0,
  salaryTypeId: null,
  salaryEventId: null,
  systemType: null,
  amount: null,
  price: null,
  priceSum: null,
  socialCosts: 0,
  message: null
}

export const salaryPeriodApi = createApi({
  base: 'salary_periods',
  normalizer: defaultEmbeddedNormalizer
})

const getSalaryCalculationParams = (params: { salaryPeriodId: number, id: number }, model: SalaryCalculationModel) => ({
  ...params,
  id: model?.salaryPeriodId ?? params.salaryPeriodId,
  subItem: 'salary_calculations',
  subItemId: params.id
})

export const salaryCalculationApi = createApi({
  base: 'salary_periods',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getSalaryCalculationParams,
  editMutateParams: getSalaryCalculationParams
})

const getSalaryCalculationRowParams = (params: { salaryPeriodId: number, salaryCalculationId: number, id: number }, model: SalaryCalculationRowModel) => ({
  ...params,
  id: params.salaryPeriodId,
  subItem: 'salary_calculations',
  subItemId: model?.salaryCalculationId ?? params.salaryCalculationId,
  subItemAction: 'rows',
  salaryCalculationRowId: model?.id ?? params.id
})

export const salaryCalculationRowApi = createApi({
  base: 'salary_periods',
  extraUrlTemplate: '{/salaryCalculationRowId}',
  normalizer: defaultEmbeddedNormalizer,
  editGetParams: getSalaryCalculationRowParams,
  editMutateParams: getSalaryCalculationRowParams
})
