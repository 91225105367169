import { timeRecordAccumulationTypesMap, timeRecordSystemTypes } from '@evelia/common/constants'
import { moment } from '@evelia/common/dateHelpers'
import chroma from 'chroma-js'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { extraTimeTimerecordAccumulationTypes, workRecordProductTypes } from '../constants'
import { getBsColor } from '../helpers/helpers'
import { findCurrentEmployee, getIsSupervisor } from './employeeSelectors'
import { findMachineWithId, getMachinesFromArgument } from './machineSelectors'
import { findMachineTypeWithId, getMachineTypesFromArgument } from './machineTypeSelectors'
import { getTimeRecordAccumulations, getTimeRecords, getTimeRecordTypes } from './timeRecordSelectors'
import { findWorkCustomStateWithId } from './workCustomStateSelectors'
import { getWorkRecordProductsByTypes } from './workRecordProductSelectors'
import { getSalaryEventsFromArgument, getWorkRecords } from './workRecordSelectors'
import { findWorkWithId, getWorkFromArguments } from './workSelectors'
import { findWorkTypeWithId } from './workTypeSelectors'

const success = '#38aa4c'
const primary = '#1d78d7'
const danger = '#f44541'
const projectBackground = '#138496'

const getWorkRecordEventBackground = workRecord => {
  let backgroundColor = primary
  if(workRecord.isSalaryEventsApproved) {
    backgroundColor = success
  } else if(workRecord.isDisapproved) {
    backgroundColor = danger
  }
  return backgroundColor
}

export const findWorkScheduleEventsBetween = createCachedSelector(
  state => state.work.workScheduleEvents.records,
  (state, startDate, endDate) => startDate,
  (state, startDate, endDate) => endDate,
  (workScheduleEvents, startDate, endDate) => workScheduleEvents.filter(workScheduleEvent => moment(workScheduleEvent.endDate).isAfter(moment(startDate)) && moment(workScheduleEvent.startDate).isBefore(moment(endDate)))
)((state, startDate, endDate) => `${startDate}-${endDate}`)

export const getWorkScheduleFullCalendarEvents = createCachedSelector(
  state => state.work.records,
  state => state.work.workScheduleEvents.records,
  state => state.workTypes.records,
  state => state?.workCustomStates?.records,
  (__state, calendarItemColorSpecifier) => calendarItemColorSpecifier,
  (workList, workScheduleEvents, workTypes, workCustomStates, calendarItemColorSpecifier) => workScheduleEvents.map(event => {
    const work = findWorkWithId(workList, event.workId)
    const getCalendarItemColor = () => {
      const color = calendarItemColorSpecifier === 'state'
        ? findWorkCustomStateWithId(workCustomStates, work?.workCustomStateId)?.color
        : findWorkTypeWithId(workTypes, work?.workTypeId)?.color

      // Color could be hash or bootstrap keyword or null
      return !color?.startsWith('#')
        ? getBsColor(color)
        : color
    }
    const backgroundColor = work?.type === 'project' ? projectBackground : getCalendarItemColor()
    return ({
      ...event,
      resourceId: event.employeeAndEmployeeRoleId,
      start: event.startDate,
      end: event.endDate,
      title: '-',
      isEditable: true,
      dbId: event.id,
      id: `workScheduleEvent_${event.id}`,
      backgroundColor,
      borderColor: backgroundColor ? chroma(backgroundColor).darken(0.5) : null
    })
  })
)((__state, calendarItemColorSpecifier) => calendarItemColorSpecifier)

const workRecordHourTypes = [workRecordProductTypes.WORK_RECORD_TYPE_HOUR, workRecordProductTypes.WORK_RECORD_TYPE_EXTRAHOUR]

export const getWorkRecordFullCalendarEvents = createCachedSelector(
  getWorkRecords,
  state => getWorkRecordProductsByTypes(state, workRecordHourTypes),
  findCurrentEmployee,
  getIsSupervisor,
  (__state, employeeId) => employeeId,
  getWorkFromArguments,
  getSalaryEventsFromArgument,
  (workRecords, hourTypeWorkRecordProducts, currentEmployee, isSupervisor, employeeId, workList, salaryEvents) =>
    workRecords
      .filter(workRecord =>
        !workRecord.isRunning && (employeeId == null || workRecord.employeeId === employeeId))
      .map(workRecord => {
        const editable = !workRecord.isSalaryEventsApproved && (isSupervisor || workRecord.employeeId === currentEmployee.id)
        const isHourType = hourTypeWorkRecordProducts.some(({ id }) => id === workRecord.workRecordProductId)

        const work = workRecord.workId ? workList.find(({ id }) => id === workRecord.workId) : null
        const isWorkSupervisor = work?.supervisorId === currentEmployee.id

        const color = getWorkRecordEventBackground(workRecord)
        const backgroundColor = !isSupervisor || isWorkSupervisor ? color : chroma(color).luminance(0.5)
        const isApprovable = !!work && salaryEvents.some(({ workRecordId }) => workRecordId === workRecord.id)
        const allDay = !(workRecord.startTime && workRecord.endTime)
        return ({
          ...workRecord,
          id: workRecord.id,
          start: workRecord.startDateTime,
          end: allDay ? moment(workRecord.endDateTime).add(1, 'day').format() : workRecord.endDateTime, // FullCalendar wants end date to be on 00:00 on next day (actual calendar event end time, excluding)
          isWithoutEndTime: !workRecord.endTime, // FullCalendar eats startTime and endTime so just a workaround
          backgroundColor,
          borderColor: chroma(backgroundColor).darken(0.7),
          editable,
          isHourType,
          isEditable: editable, // duplicated so value can be read later on
          resourceId: workRecord.employeeId,
          allDay,
          isApprovable,
          isWorkSupervisor
        })
      })
)((__state, employeeId) => `${employeeId}`)

export const getWorkMachineFullCalendarEvents = createCachedSelector(
  state => state.work.workMachines.records,
  (__state, workId) => workId,
  getMachinesFromArgument,
  getMachineTypesFromArgument,
  (workMachines, workId, machines, machineTypes) =>
    (workId
      ? workMachines.filter(workMachine => workMachine.workId === workId)
      : workMachines)
      .map(workMachine => {
        const machine = findMachineWithId(machines, workMachine.machineId)
        const machineType = findMachineTypeWithId(machineTypes, machine?.machineTypeId)
        const backgroundColor = machineType?.color
        const editable = true
        return {
          ...workMachine,
          start: workMachine.startDate,
          end: workMachine.endDate,
          editable,
          isEditable: editable, // duplicated so value can be read later on
          resourceId: workMachine.machineId,
          backgroundColor: machineType?.color,
          borderColor: machineType?.color ? chroma(backgroundColor).darken(0.5) : null
        }
      })
)((__state, workId) => `${workId}`)

export const parseFullCalendarHash = createSelector(
  state => state.router.location.hash,
  hash => {
    const [base, viewName, date] = hash.split('/')
    let viewNameString = viewName ? decodeURIComponent(viewName) : undefined
    let dateString = date ? decodeURIComponent(date) : undefined
    if(!dateString && viewNameString && moment(viewNameString, moment.ISO_8601).isValid()) {
      dateString = viewNameString
      viewNameString = undefined
    }
    return {
      base: base || '#',
      viewName: viewNameString,
      date: dateString
    }
  }
)

export const getTimeRecordFullCalendarEvents = createCachedSelector(
  getTimeRecords,
  getTimeRecordTypes,
  (__state, opts) => !!opts?.noAway,
  (state, opts) => opts?.employeeId ?? findCurrentEmployee(state)?.id,
  (timeRecords, timeRecordTypes, noAway, employeeId) =>
    timeRecords
      .filter(timeRecord => !!timeRecord.endedAt && timeRecord.employeeId === employeeId)
      .map(timeRecord => {
        const timeRecordType = timeRecordTypes.find(timeRecordType => timeRecordType.id === timeRecord.timeRecordTypeId)
        if(noAway && timeRecordType.systemType === timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_AWAY) {
          return false
        }
        const editable = false
        return ({
          ...timeRecord,
          id: timeRecord.id,
          start: timeRecord.startedAt,
          end: timeRecord.endedAt,
          editable,
          isEditable: editable, // duplicated so value can be read later on
          allDay: false,
          title: timeRecordType.name,
          classNames: [`bg-${timeRecordType.color}`, `border-${timeRecordType.color}`],
          timeRecordType
        })
      }).filter(Boolean)
)((state, opts) => `${opts?.noAway}_${opts?.employeeId}`)

export const getTimeRecordAccumulationFullCalendarEvents = createCachedSelector(
  getTimeRecordAccumulations,
  (state, opts) => opts?.employeeId ?? findCurrentEmployee(state)?.id,
  (state, opts) => opts.valueFormatter,
  (timeRecordAccumulations, employeeId, valueFormatter) =>
    timeRecordAccumulations
      .filter(timeRecord => timeRecord.employeeId === employeeId)
      .map(timeRecordAccumulation => {
        const editable = timeRecordAccumulation.value > 0 && extraTimeTimerecordAccumulationTypes.includes(timeRecordAccumulation.type)
        const typeData = timeRecordAccumulationTypesMap[timeRecordAccumulation.type]
        const color = timeRecordAccumulation.value < 0 ? typeData.negativeColor : typeData.positiveColor
        return ({
          ...timeRecordAccumulation,
          id: timeRecordAccumulation.id,
          start: timeRecordAccumulation.date,
          editable,
          isEditable: editable, // duplicated so value can be read later on
          allDay: true,
          title: `${typeData.name}: ${valueFormatter(timeRecordAccumulation.type, timeRecordAccumulation.value)}`,
          classNames: [`bg-${color}`, `border-${color}`, 'opacity-50']
        })
      }).filter(Boolean)
)((state, opts) => `${opts?.employeeId}`)
