import { BaseIdModel } from '@evelia/common/types'
import constant from 'lodash/constant'

import { createCRUDApi } from './createCRUDApi'

export type PurchaseOrderStateModel = {
  isSystem: boolean
  name: string
  color: string
  state: number
  createdAt: string | null
  updatedAt: string | null
} & BaseIdModel

const { api, useRecords, useMutationsHook } = createCRUDApi<PurchaseOrderStateModel, void, 'purchaseOrderStates'>({
  path: 'purchaseOrderStates',
  queryDefinition: { query: constant('') },
  socketMatchers: {
    created: constant(true),
    updated: constant(true)
  },
  titles: {
    singular: 'ostotilaustila',
    plural: 'ostotilaustilat',
    genetive: 'ostotilaustilan',
    pluralGenetive: 'ostotilaustilojen'
  }
})

export const purchaseOrderStateApi = api
export const usePurchaseOrderStateMutations = useMutationsHook
export const usePurchaseOrderStates = useRecords
