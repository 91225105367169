import { get } from 'react-hook-form'
import { numberToCommaString, round2decimals } from '@evelia/common/helpers'
import isString from 'lodash/isString'

import useToggle from '../../hooks/useToggle'
import FormPlainInput, { FormPlainInputProps } from './FormPlainInput'

const parseDisplayNumber = (value, round, previewFormatter = numberToCommaString) => {
  if(value == null || value === '') {
    return ''
  }
  if(round && isString(value) && value.match(/[,.]$/)) {
    return value
  }
  const numeric = round
    ? round2decimals(Number.parseFloat(value.toString().replace(',', '.')))
    : value
  if(Number.isNaN(numeric)) {
    return value
  }
  return previewFormatter(numeric)
}

interface FormPlainNumberInputProps extends FormPlainInputProps {
  isAlwaysRounded?: boolean
  previewFormatter?: (numeric: number) => string
}

const FormPlainNumberInput = ({
  value,
  inputProps,
  isAlwaysRounded,
  previewFormatter,
  ...props
}: FormPlainNumberInputProps) => {
  const [isFocused, { enable, disable }] = useToggle()
  const extendedInputProps = {
    ...inputProps,
    onFocus: event => {
      enable()
      inputProps?.onFocus?.(event)
    },
    onBlur: event => {
      disable()
      inputProps?.onBlur?.(event)
    }
  }
  const isError = !!get(props.errors, props.name)
  const round = !isError && (isAlwaysRounded || !isFocused)
  return (
    <FormPlainInput
      {...props}
      value={parseDisplayNumber(value, round, previewFormatter)}
      inputProps={extendedInputProps}
    />
  )
}

export default FormPlainNumberInput
