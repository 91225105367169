import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { Input } from 'reactstrap'
import { UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { dateFormats, formatDate, formatDateOrEmpty, moment } from '@evelia/common/dateHelpers'
import { faArrowsAltV, faCheck, faRedo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CellContext,
  ColumnDef,
  ColumnHelper,
  RowData,
  SortingFnOption,
  sortingFns
} from '@tanstack/react-table'
import type { Primitive } from 'type-fest'

import { checkedIcon, formatEur, formatWithUnitMemoized, stopPropagationEvent } from '../../helpers/helpers'
import IconButton from '../Buttons/IconButton'
import ContextFormWrapper from '../hookContextForm/ContextFormWrapper'
import FormCheckboxInput from '../hookForm/FormCheckboxInput'
import FormColorInput from '../hookForm/FormColorInput'
import FormDateInput from '../hookForm/FormDateInput'
import FormInput from '../hookForm/FormInput'
import FormNumberInput from '../hookForm/FormNumberInput'
import FormPlainNumberInput from '../hookForm/FormPlainNumberInput'
import FormSelectInput from '../hookForm/FormSelectInput'
import FormThemeColorInput from '../hookForm/FormThemeColorInput'
import FormTimeInput from '../hookForm/FormTimeInput'
import ColorBadge from '../misc/ColorBadge'
import { DeleteButton } from '../Table/defaultGridComponents'
import PlainRowCell from './PlainRowCell'
import PlainRowInput from './PlainRowInput'

// Pinning makes columns sticky. Can be restricted with column meta.onlyPinOnEdit
export const actionColumnPinning = {
  columnPinning: {
    right: [
      'actions'
    ]
  }
}

/**
 * Builds basic double column for data table. Please build a custom cell if structure requires more logic.
 * Keep this as simple as possible.
 * First column uses `column.id` generated by `columnHelper.accessor(id)`.
 * NOTE: cells must use `row.getValues(column.id)` because id will get overwritten
 */
export const buildDoubleColumnCell = ({
  secondaryName,
  primaryCell = defaultTextColumn.cell,
  secondaryCell = defaultTextColumn.cell,
  primaryHeader = null,
  secondaryHeader = null
}) => ({
  header: (
    <>
      {primaryHeader} <br />
      {secondaryHeader}
    </>
  ),
  cell: cellProps => (
    <>
      <div>
        {primaryCell(cellProps)}
      </div>
      <div>
        {secondaryCell({ ...cellProps, column: { ...cellProps.column, id: secondaryName } })}
      </div>
    </>
  )
})
/**
 * Used for giving opposite weight for empty or nullish values than tanstack datatable's default sorting functions
 */
const nullsLastSorter = (rowA, rowB, columnId) => {
  const valueALength = rowA.getValue(columnId)?.toString().length || 0
  const valueBLength = rowB.getValue(columnId)?.toString().length || 0
  // If either one is empty then mark rowB to be larger
  if(!valueALength || !valueBLength) {
    return valueBLength - valueALength
  }
  return null
}

export const dataTableSortingFunctions = {
  nullsLastAlphanumeric: (rowA, rowB, columnId) => {
    const nullishResult = nullsLastSorter(rowA, rowB, columnId)
    if(nullishResult != null) {
      return nullishResult
    }
    return sortingFns.alphanumeric(rowA, rowB, columnId)
  },
  dateTimeFormatSort: (rowA, rowB, columnId) => {
    const nullishResult = nullsLastSorter(rowA, rowB, columnId)
    if(nullishResult != null) {
      return nullishResult
    }
    const valueA = rowA.getValue(columnId)
    const valueB = rowB.getValue(columnId)
    const unixA = moment(valueA, dateFormats.finnishDateTimeFormatShort).unix()
    const unixB = moment(valueB, dateFormats.finnishDateTimeFormatShort).unix()
    return unixA - unixB
  }
}

const TextColumnInput = <TData, TValue>({ row, column, table, isEditable }: CellContext<TData, TValue>) => {
  if(isEditable) {
    return (
      <ContextFormWrapper
        name={column.id}
        FormInput={FormInput}
        meta={column.columnDef.meta}
        tableMeta={table.options.meta}
      />
    )
  }
  return <PlainRowInput getValue={row.getValue} name={column.id} />
}

export const getDefaultTextColumn = <TData, TValue>() => ({
  cell: TextColumnInput<TData, TValue>,
  sortingFn: 'nullsLastAlphanumeric' as SortingFnOption<TData>
})

export const defaultTextColumn = getDefaultTextColumn()

export const NumberColumnInput = <TData, TValue extends Primitive = number>({
  row,
  column,
  table,
  isEditable
}: CellContext<TData, TValue>) => {
  if(isEditable) {
    return (
      <ContextFormWrapper
        name={column.id}
        FormInput={FormNumberInput}
        meta={column.columnDef.meta}
        tableMeta={table.options.meta}
      />
    )
  }
  return <PlainRowInput<TValue> getValue={row.getValue} name={column.id} Input={FormPlainNumberInput} isAlwaysRounded />
}

export const getDefaultNumberColumn = () => ({
  size: 100,
  cell: NumberColumnInput
})

export const defaultNumberColumn = getDefaultNumberColumn()

export const defaultUnitNumberColumn = <TData extends RowData, TValue extends Primitive = number>(unit: string, { extraEditMeta = {} } = {}): Omit<ColumnDef<TData, TValue>, 'accessorFn'> => ({
  size: 100,
  cell: ({
    column,
    table,
    isEditable,
    row
  }) => {
    if(isEditable) {
      return (
        <ContextFormWrapper
          name={column.id}
          append={unit}
          FormInput={FormNumberInput}
          meta={{
            ...column.columnDef.meta,
            ...extraEditMeta
          }}
          tableMeta={table.options.meta}
        />
      )
    }
    return (
      <PlainRowInput<TValue>
        getValue={row.getValue}
        name={column.id}
        Input={FormPlainNumberInput}
        previewFormatter={formatWithUnitMemoized(unit)}
        isAlwaysRounded
        meta={column.columnDef.meta}
        tableMeta={table.options.meta}
        row={row}
      />
    )
  }
})

export const defaultPercentageColumn = defaultUnitNumberColumn('%')

export const PriceNumberColumnInput = <TData, TValue extends Primitive = number>({
  column,
  table,
  isEditable,
  row,
  extraEditMeta = {}
}: CellContext<TData, TValue> & { extraEditMeta?: object }) => {
  if(isEditable) {
    return (
      <ContextFormWrapper
        name={column.id}
        append='€'
        FormInput={FormNumberInput}
        meta={{
          ...column.columnDef.meta,
          ...extraEditMeta
        }}
        tableMeta={table.options.meta}
      />
    )
  }
  return (
    <PlainRowInput<TValue>
      getValue={row.getValue}
      name={column.id}
      previewFormatter={formatEur}
      Input={FormPlainNumberInput}
      isAlwaysRounded
      meta={column.columnDef.meta}
      tableMeta={table.options.meta}
      row={row}
    />
  )
}

export const getDefaultPriceNumberColumn = () => ({
  cell: PriceNumberColumnInput
})

export const defaultPriceNumberColumn = getDefaultPriceNumberColumn()

export const defaultCheckboxColumn = {
  size: 100,
  cell: ({
    row,
    column,
    table,
    isEditable
  }) => {
    if(isEditable) {
      return (
        <ContextFormWrapper
          name={column.id}
          FormInput={FormCheckboxInput}
          meta={column.columnDef.meta}
          tableMeta={table.options.meta}
        />
      )
    }
    return (
      <Input
        name={column.id}
        type='checkbox'
        checked={row.getValue(column.id)}
        data-testid={column.id}
        className='mt-2'
      />
    )
  }
}

export const DateColumnInput = <TData, >({ row, column, isEditable, table }: CellContext<TData, string>) => {
  if(isEditable) {
    return (
      <ContextFormWrapper
        name={column.id}
        FormInput={FormDateInput}
        meta={column.columnDef.meta}
        tableMeta={table.options.meta}
      />
    )
  }
  return (
    <PlainRowInput
      value={formatDateOrEmpty(row.getValue(column.id))}
      name={column.id}
      meta={column.columnDef.meta}
      tableMeta={table.options.meta}
      row={row}
    />
  )
}

export const defaultDateColumn = {
  size: 130,
  cell: DateColumnInput
}

export const RowActionButtons = ({ toggleRowEditingApi }) => {
  const { formState } = useFormContext() ?? {}
  const { isSubmitting } = formState
  return (
    <div onClick={stopPropagationEvent} onKeyDown={stopPropagationEvent}>
      <IconButton
        color='success'
        icon={faCheck}
        type='submit'
        disabled={isSubmitting}
        testId='saveRow'
      />
      <IconButton
        color='danger'
        icon={faRedo}
        onClick={toggleRowEditingApi.disable}
        disabled={isSubmitting}
        testId='cancelEditing'
      />
    </div>
  )
}
RowActionButtons.propTypes = {
  toggleRowEditingApi: PropTypes.shape({
    toggle: PropTypes.func.isRequired,
    disable: PropTypes.func.isRequired,
    enable: PropTypes.func.isRequired
  })
}

export const RowDragButton = ({ id, disabled }: { id: UniqueIdentifier, disabled: boolean }) => {
  const { attributes, listeners, setActivatorNodeRef } = useSortable({ id })
  return (attributes && !disabled)
    ? (
      <div
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        className='drag-row-indicator d-inline-block text-secondary rounded-3 overflow-hidden bg-light'
        data-testid='dragRow'
      >
        <FontAwesomeIcon
          icon={faArrowsAltV}
          title='Järjestä'
          className='px-2 py-1'
          size='lg'
        />
      </div>
      )
    : null
}

export const defaultActionsCell = {
  id: 'actions',
  cell: ({ isEditable, toggleRowEditingApi }) => {
    return isEditable
      ? <RowActionButtons key='actionButton' toggleRowEditingApi={toggleRowEditingApi} />
      : null
  },
  size: 80,
  enableResizing: false,
  enableFilter: false
}

export const defaultFullActionsCell = {
  id: 'actions',
  cell: ({
    isEditable,
    isDeletable,
    toggleRowEditingApi,
    table,
    row
  }) => {
    return (
      <>
        {!isEditable && isDeletable
          ? <DeleteButton key='deleteButton' onClick={() => table.options?.meta.onDelete(row.original)} />
          : null}
        {isEditable
          ? <RowActionButtons key='actionButton' toggleRowEditingApi={toggleRowEditingApi} />
          : null}
      </>
    )
  },
  size: 80,
  enableResizing: false,
  enableFilter: false
}

// use columnHelper to satisfy typescript - we could write the column without it but angry typescript gods would yell every time
export const dataTableColorChooserCell = <TData extends { color?: string | null } >(columnHelper: ColumnHelper<TData>) => dataTableColorChooser(FormColorInput, columnHelper)

export const dataTableThemeColorChooserCell = <TData extends { color?: string | null } >(columnHelper: ColumnHelper<TData>) => dataTableColorChooser(FormThemeColorInput, columnHelper)

const dataTableColorChooser = <TData extends { color?: string | null } >(FormInput: typeof FormColorInput | typeof FormThemeColorInput, columnHelper: ColumnHelper<TData>) =>
  columnHelper.accessor(({ color }) => color, {
    id: 'color',
    header: 'Väri',
    size: 75,
    cell: ({ column, getValue, isEditable }: CellContext<TData, string>) => {
      if(isEditable) {
        return (
          <ContextFormWrapper
            FormInput={FormInput}
            name='color'
            value={getValue()}
            resetValue
            setNull
          />
        )
      }
      return <PlainRowCell name={column.id}><ColorBadge color={getValue()} className='h-100 ms-n2' /></PlainRowCell>
    }
  })
export const buildTextColumn = inputProps => ({
  cell: ({ row, column, table, isEditable }) => {
    if(isEditable) {
      return (
        <ContextFormWrapper
          name={column.id}
          FormInput={FormInput}
          meta={column.columnDef.meta}
          tableMeta={table.options.meta}
          inputProps={inputProps}
        />
      )
    }
    return <PlainRowInput getValue={row.getValue} name={column.id} />
  },
  sortingFn: 'nullsLastAlphanumeric'
})

export const CheckBoxIconColumnInput = <TData, >({ getValue, column, isEditable, table }: CellContext<TData, boolean>) => {
  if(isEditable) {
    return (
      <ContextFormWrapper
        name={column.id}
        FormInput={FormCheckboxInput}
        meta={column.columnDef.meta}
        tableMeta={table.options.meta}
        formGroupClassName='ms-2'
      />
    )
  }
  return <PlainRowCell name={column.id}>{checkedIcon(getValue())}</PlainRowCell>
}

export const TimeColumnInput = <TData, >({ getValue, column, isEditable, table }: CellContext<TData, string>) => {
  if(isEditable) {
    return (
      <ContextFormWrapper
        name={column.id}
        FormInput={FormTimeInput}
        meta={column.columnDef.meta}
        tableMeta={table.options.meta}
      />
    )
  }
  return <PlainRowCell name={column.id} value={getValue()} />
}

export const dateColumn = ({
  size: 150,
  cell: ({ row, column, isEditable, table }) => {
    if(isEditable) {
      return (
        <ContextFormWrapper
          name={column.id}
          FormInput={FormDateInput}
          meta={column.columnDef.meta}
          tableMeta={table.options.meta}
        />
      )
    }
    const value = row.getValue(column.id)
    const formattedDate = value ? formatDate(value) : '-'
    return (
      <PlainRowInput
        value={formattedDate}
        name={column.id}
      />
    )
  }
})
interface SelectColumnInputProps<TOption> {
  options: TOption[]
  labelKey?: keyof TOption
  valueKey?: keyof TOption
  nullRender?: string
}
export const selectColumnInput = <TData, TValue, TOption>({
  options,
  labelKey = 'name' as keyof TOption,
  valueKey = 'id' as keyof TOption,
  nullRender
}: SelectColumnInputProps<TOption>) =>
    ({ getValue, isEditable, column, table }: CellContext<TData, TValue>) => {
      if(isEditable) {
        return (
          <ContextFormWrapper
            name={column.id}
            FormInput={FormSelectInput}
            positionFixed
            options={options}
            labelKey='text'
            valueKey='value'
            meta={column.columnDef.meta}
            tableMeta={table.options.meta}
          />
        )
      }
      const value = options.find(option => option[valueKey] === getValue())
      return <PlainRowCell name={column.id} value={value?.[labelKey] as string ?? nullRender} />
    }
