import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { denominationSystemTypes } from '@evelia/common/constants'
import {
  BaseIdModel,
  BaseSyntheticIdModel,
  ContactModel,
  InsuranceCompanyModel,
  InsuranceModel,
  OfferCustomStateModel,
  OfferExtraInfoModel,
  OfferModel,
  OfferPostModel,
  OfferPostRowModel,
  PacketIndustryModel,
  SalaryCalculationModel,
  SalaryCalculationRowModel,
  SalaryPeriodModel
} from '@evelia/common/types'
import {
  TimeRecordAccumulationModel,
  TimeRecordAccumulationStatModel,
  TimeRecordModel,
  TimeRecordSiteModel,
  TimeRecordTypeModel
} from '@evelia/common/types/timeRecordTypes'
import { EntityId } from '@reduxjs/toolkit'
import { ValueOf } from 'type-fest'

import { store } from '.'
import { FederatedCredentialModel } from './api/federatedCredentialApi'
import { OccupationClassificationModel } from './api/occupationClassificationApi'
import { DeliveryTypeModel } from './api/rtk/deliveryTypeApi'
import { EmployeeLevelModel } from './api/rtk/employeeLevelsApi'
import { EmployeeRolesModel } from './api/rtk/employeeRolesApi'
import { EmployeeModel } from './api/rtk/employeesApi'
import {
  ContactFileLinkModel,
  FileModel,
  OfferFileLinkModel,
  ProductFileLinkModel,
  PurchaseOrderFileLinkModel,
  ReceiverFileLinkModel,
  SalaryCalculationFileModel
} from './api/rtk/fileApi'
import { TableOptionsModel } from './api/rtk/index'
import { MemoModel } from './api/rtk/memoApi'
import { ProductModel } from './api/rtk/productApi'
import { ReceiverApproverModel, ReceiverModel } from './api/rtk/receiverApi'
import { SystemCustomerSettingsModel } from './api/rtk/settingsApi'
import { TargetModel } from './api/rtk/targetApi'
import {
  EmployeeSystemCustomerModel,
  PricingRuleModel,
  PricingRuleSetFallbackModel,
  PricingRuleSetModel,
  SalaryGroupModel,
  SalaryTypeModel,
  SystemCustomerAnnualInvoicingModel,
  WageIncomeTypeModel
} from './api/types/systemCustomerTypes'
import { ProductWarehouseStatsModel, WarehouseActionModel, WarehouseModel } from './api/types/warehouseTypes'
import { EmploymentModel, EmploymentSalaryTypeModel, TaxDeductionCardModel, WageEarnerModel } from './api/wageEarnerApi'
import { GenericState } from './helpers/sliceHelpers'
import { Modify } from './helpers/typeHelpers'

export interface ReducerMetadata {
  busy: boolean
  lastFetched: string | null
  fetchParams: Record<string, unknown>
}

export interface ReducerSearchData<Id extends EntityId = number> {
  isBusy: boolean
  searchResultIds: Id[]
  error: string | null
  searchTerm: string | null
}

export interface ReduxCrudRouter<T extends BaseIdModel | BaseSyntheticIdModel, Id extends EntityId = number> {
  records: T[]
  metadata: ReducerMetadata
  search: ReducerSearchData<Id>
  tableOptions?: TableOptionsModel
}

export interface ReduxCrudRouterStats<T extends BaseIdModel | BaseSyntheticIdModel, Id extends EntityId = number> {
  records: Record<Id, T>
  isBusy: boolean
}

type TODO = BaseIdModel

// TODO: lisätään tyyppejä tarvittaessa
export interface RootState {
  systemCustomer: {
    salaryTypes: ReduxCrudRouter<SalaryTypeModel>
    salaryGroups: ReduxCrudRouter<SalaryGroupModel>
    wageIncomeTypes: ReduxCrudRouter<WageIncomeTypeModel>
    pricingRules: ReduxCrudRouter<PricingRuleModel>
    pricingRuleSets: ReduxCrudRouter<PricingRuleSetModel>
    pricingRuleFallbacks: ReduxCrudRouter<PricingRuleSetFallbackModel>
    packetIndustries: ReduxCrudRouter<PacketIndustryModel>
    systemCustomerAnnualInvoicings: ReduxCrudRouter<SystemCustomerAnnualInvoicingModel>
    settingsData: SystemCustomerSettingsModel
  }
  whoAmI: {
    data: {
      employeeId: number
      userAccountId: number
      userAccountEmail: string
      isWizardRun: boolean
      isActive: boolean
      isInProduction: boolean
      isMaventaEnabled: boolean
      isFennoaEnabled: boolean
      isMfaForced: boolean
      serviceLevel: string
      pendingServiceLevel: string | null
      internallyEnabledFeatures: string[]
      stulAuthenticatedUntil: string | null
      mfaStatus: 'valid' | null
      formTemplateLimit: number
      denominationSystemType: ValueOf<typeof denominationSystemTypes>
    }
    employeeSystemCustomers: EmployeeSystemCustomerModel[]
  }
  timeRecords: {
    timeRecordTypes: ReduxCrudRouter<TimeRecordTypeModel>
    timeRecordSites: ReduxCrudRouter<TimeRecordSiteModel>
    timeRecordAccumulations: {
      stats: ReduxCrudRouterStats<TimeRecordAccumulationStatModel>
    } & ReduxCrudRouter<TimeRecordAccumulationModel>
  } & ReduxCrudRouter<TimeRecordModel>
  employees: {
    levels: ReduxCrudRouter<EmployeeLevelModel>
  } & ReduxCrudRouter<EmployeeModel>
  employeeRoles: ReduxCrudRouter<EmployeeRolesModel>
  insurances: GenericState<InsuranceModel>
  insuranceCompanies: GenericState<InsuranceCompanyModel>
  occupationClassifications: GenericState<OccupationClassificationModel>
  offerCustomStates: ReduxCrudRouter<OfferCustomStateModel>
  offers: {
    files: ReduxCrudRouter<OfferFileLinkModel>
    memos: ReduxCrudRouter<TODO>
    offerDefaults: ReduxCrudRouter<TODO>
    postRows: ReduxCrudRouter<OfferPostRowModel>
    posts: ReduxCrudRouter<OfferPostModel>
    extraInfo: OfferExtraInfoModel[]
  } & ReduxCrudRouter<OfferModel>
  supplyOffers: ReduxCrudRouter<TODO>
  wageEarners: {
    wageEarners: GenericState<WageEarnerModel>
    taxDeductionCards: GenericState<TaxDeductionCardModel>
    employments: GenericState<EmploymentModel>
    employmentSalaryTypes: GenericState<EmploymentSalaryTypeModel, string>
  }
  federatedCredentials: GenericState<FederatedCredentialModel>
  purchaseOrders: {
    memos: GenericState<MemoModel>
    files: GenericState<PurchaseOrderFileLinkModel>
  }
  salaryPeriods: {
    salaryPeriods: GenericState<SalaryPeriodModel>
    salaryCalculations: GenericState<SalaryCalculationModel>
    salaryCalculationRows: GenericState<SalaryCalculationRowModel>
    salaryCalculationFiles: GenericState<SalaryCalculationFileModel>
  }
  products: {
    files: ReduxCrudRouter<ProductFileLinkModel>
    productTasks: ReduxCrudRouter<TODO>
    productWarehouseStats: ReduxCrudRouter<ProductWarehouseStatsModel>
  } & ReduxCrudRouter<ProductModel>
  warehouses: {
    warehouseActions: ReduxCrudRouter<WarehouseActionModel>
    warehouseProductStats: ReduxCrudRouter<ProductWarehouseStatsModel>
  } & ReduxCrudRouter<WarehouseModel>
  deliveryTypes: GenericState<DeliveryTypeModel>
  contacts: {
    contactExtras: ReduxCrudRouter<TODO>
    customers: ReduxCrudRouter<TODO>
    files: ReduxCrudRouter<ContactFileLinkModel>
    stats: ReduxCrudRouter<TODO>
    targets: ReduxCrudRouter<TargetModel>
  } & ReduxCrudRouter<ContactModel>
  files: ReduxCrudRouter<FileModel>
  receivers: {
    approvers: ReduxCrudRouter<ReceiverApproverModel>
    files: ReduxCrudRouter<ReceiverFileLinkModel>
  } & ReduxCrudRouter<ReceiverModel>
}

type AppDispatch = typeof store.dispatch

// Override inferred types with RootState
export type EveliaRootState = Modify<ReturnType<typeof store.getState>, RootState>

export const useTypedDispatch = useDispatch as () => AppDispatch
export const useTypedSelector = useSelector as TypedUseSelectorHook<EveliaRootState>
