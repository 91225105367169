import { BaseIdModel } from '@evelia/common/types'

import { createApi, defaultEmbeddedNormalizer } from '../helpers/apiHelpers'

export type ApiCertificateModel = {
  type: number
  isPending: boolean
  validUntil: string
  isDisabled: boolean
} & BaseIdModel

export const apiCertificateApi = createApi({
  base: 'system_customers/api_certificates',
  normalizer: defaultEmbeddedNormalizer
})
