import { SalaryCalculationRowModel } from '@evelia/common/types'
import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { defaultSalaryCalculationRowModel } from '../api/salaryPeriodApi'
import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const salaryCalculationRowAdapter = createEntityAdapter<SalaryCalculationRowModel>({})

const salaryCalculationRowSlice = createGenericSlice({
  name: 'salaryCalculationRows',
  entityAdapter: salaryCalculationRowAdapter,
  defaultModel: defaultSalaryCalculationRowModel
})

export const salaryCalculationRowEntitySelectors = salaryCalculationRowAdapter.getSelectors<EveliaRootState>(state => state.salaryPeriods.salaryCalculationRows)
export const salaryCalculationRowActions = salaryCalculationRowSlice.actions

export default salaryCalculationRowSlice
