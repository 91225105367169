import memoize from 'micro-memoize'
import { ValueOf } from 'type-fest'

import { SystemCustomerSettingsModel } from '../../api/rtk/settingsApi'
import {
  accessLevels,
  actionKeys,
  featureKeys,
  serviceLevels,
  serviceLevelValues
} from '../../constants'
import { isProductionEnv, isStagingEnv } from '../../helpers/envHelpers'

export const stages = {
  PRODUCTION: 0,
  BETA: 1,
  EXPERIMENTAL: 2,
  ALPHA: 3,
  PREVIEW: 4
} as const

export const stageProperties = {
  [stages.PRODUCTION]: {
    colorClass: '',
    sidebarText: ''
  },
  [stages.BETA]: {
    colorClass: 'text-warning',
    sidebarText: ''
  },
  [stages.EXPERIMENTAL]: {
    colorClass: 'text-danger',
    sidebarText: 'beta'
  },
  [stages.ALPHA]: {
    colorClass: 'text-danger',
    sidebarText: 'alpha'
  },
  [stages.PREVIEW]: {
    colorClass: 'text-danger',
    sidebarText: 'dev'
  },
  default: {
    colorClass: '',
    sidebarText: ''
  }
}

const defaultMinAccessLevel = accessLevels.ALL
const defaultMinServiceLevel = serviceLevelValues[serviceLevels.SERVICE_LEVEL_INVOICING]
export const defaultStage = stages.PRODUCTION

const defaultFeaturePermissions = {
  minServiceLevel: defaultMinServiceLevel,
  minAccessLevel: defaultMinAccessLevel,
  stage: defaultStage,
  requireInternalPermission: false,
  requireSetting: null
}

type FeaturePermissionsConfig = {
  feature: ValueOf<typeof actionKeys>
  minServiceLevel?: ValueOf<typeof serviceLevelValues>
  minAccessLevel?: ValueOf<typeof accessLevels>
  stage?: ValueOf<typeof stages>
  requireInternalPermission?: boolean | [ValueOf<typeof featureKeys>]
  requireSetting?: ((settings: SystemCustomerSettingsModel['settings']) => boolean) | null
}
type FeaturePermission = Required<FeaturePermissionsConfig>

const featurePermissionsConfig: FeaturePermissionsConfig[] = [
  { feature: actionKeys.INVOICES, minAccessLevel: accessLevels.SUPERVISOR },
  { feature: actionKeys.INBOUND_INVOICES, minAccessLevel: accessLevels.SUPERVISOR },
  { feature: actionKeys.INBOUND_INVOICE_ACTIONS, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.RECEIVERS, minAccessLevel: accessLevels.SUPERVISOR },
  { feature: actionKeys.INVOICES, minAccessLevel: accessLevels.SUPERVISOR },
  { feature: actionKeys.REPORTS, minAccessLevel: accessLevels.FINANCE },
  { feature: actionKeys.SETTINGS, minAccessLevel: accessLevels.FINANCE },
  { feature: actionKeys.FINANCE, minAccessLevel: accessLevels.FINANCE },
  { feature: actionKeys.SETTINGS_WIZARD, minAccessLevel: accessLevels.ADMINISTRATION },
  { feature: actionKeys.WORK_RECORDS, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.RECEIPT, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.PROJECTS, minAccessLevel: accessLevels.SUPERVISOR, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.FILES },
  { feature: actionKeys.INSTALMENTS, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.PRODUCTS, minAccessLevel: accessLevels.RESTRICTED_USER },
  { feature: actionKeys.PRODUCTS, minAccessLevel: accessLevels.RESTRICTED_USER },
  { feature: actionKeys.CALENDAR, minAccessLevel: accessLevels.USER_WITHOUT_PRICES },
  { feature: actionKeys.TARGETS, minAccessLevel: accessLevels.USER_WITHOUT_PRICES },
  { feature: actionKeys.CUSTOMERS, minAccessLevel: accessLevels.USER_WITHOUT_PRICES },
  { feature: actionKeys.CONTACTS, minAccessLevel: accessLevels.USER_WITHOUT_PRICES },
  { feature: actionKeys.CASH_RECEIPTS, minAccessLevel: accessLevels.USER, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.OFFERS, minAccessLevel: accessLevels.SUPERVISOR, requireInternalPermission: true, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.RTV, minAccessLevel: accessLevels.SUPERVISOR, requireInternalPermission: true, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.SUBRECOGNITION, requireInternalPermission: true, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO] },
  { feature: actionKeys.SYSTEM_MESSAGES },
  { feature: actionKeys.SYSTEM_MESSAGES_VIEW_ALL, minAccessLevel: accessLevels.SUPERVISOR },
  { feature: actionKeys.WORK_SYSTEM_MESSAGES, minAccessLevel: accessLevels.USER_WITHOUT_PRICES },
  { feature: actionKeys.WORK_VIEW_ALL, minAccessLevel: accessLevels.USER_WITHOUT_PRICES },
  { feature: actionKeys.WORK_PURCHACE_PRICES, minAccessLevel: accessLevels.USER },
  { feature: actionKeys.TICKETS, minAccessLevel: accessLevels.USER_WITHOUT_PRICES },
  { feature: actionKeys.SUPPLY_OFFERS, minAccessLevel: accessLevels.USER },
  { feature: actionKeys.WAREHOUSES, requireSetting: settings => !!settings.isWarehouseEnabled, requireInternalPermission: true, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_FULL] },
  { feature: actionKeys.TIME_RECORDS, requireInternalPermission: true, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO] },
  { feature: actionKeys.MAVENTA, requireInternalPermission: true },
  { feature: actionKeys.MACHINES, requireInternalPermission: true },
  { feature: actionKeys.INBOUND_MESSAGES, requireInternalPermission: true, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO] },
  { feature: actionKeys.CASH_FLOW_FORECAST },
  { feature: actionKeys.WORK_RECURRENCE_RULES, minAccessLevel: accessLevels.SUPERVISOR, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO], requireInternalPermission: [featureKeys.RECURRENCES] },
  { feature: actionKeys.TICKET_RECURRENCE_RULES, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO], requireInternalPermission: [featureKeys.RECURRENCES] },
  { feature: actionKeys.WORK_INVOICING_RULES, minAccessLevel: accessLevels.SUPERVISOR, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO], requireInternalPermission: [featureKeys.RECURRENCES] },
  { feature: actionKeys.DENOMINATIONS, minAccessLevel: accessLevels.SUPERVISOR, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO], requireInternalPermission: true },
  { feature: actionKeys.COST_CENTRES, minAccessLevel: accessLevels.SUPERVISOR, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO], requireInternalPermission: true },
  { feature: actionKeys.INVOICE_FORM_TYPES },
  { feature: actionKeys.WORK_EDIT_PRICES, minAccessLevel: accessLevels.RESTRICTED_USER },
  { feature: actionKeys.INVOICING_TYPE, requireInternalPermission: true },
  { feature: actionKeys.INBOUND_INVOICE_SALARIES, minAccessLevel: accessLevels.FINANCE },
  { feature: actionKeys.MAPS, requireInternalPermission: true },
  { feature: actionKeys.SALARY_CALCULATION, minAccessLevel: accessLevels.FINANCE, stage: stages.BETA, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO], requireInternalPermission: true },
  { feature: actionKeys.FORM_TEMPLATES, requireInternalPermission: true, minAccessLevel: accessLevels.ADMIN },
  { feature: actionKeys.PURCHASE_ORDERS, requireInternalPermission: true, minAccessLevel: accessLevels.USER, minServiceLevel: serviceLevelValues[serviceLevels.SERVICE_LEVEL_PRO], stage: stages.BETA }
]
const featurePermissions: FeaturePermission[] = featurePermissionsConfig.map(feature => ({ ...defaultFeaturePermissions, ...feature })) // Ensure each feature has default values

export const findFeaturePermissionsByKey = memoize(featureKey =>
  featurePermissions.find(featurePermission => featurePermission.feature === featureKey) || defaultFeaturePermissions,
{ maxSize: featurePermissions.length })

export const getSystemCustomerPermissions = memoize(systemCustomer => {
  const isExperimentalFeaturesEnabled = !!systemCustomer.settingsData.settings.enableExperimentalFeatures
  const systemCustomerStage =
    isExperimentalFeaturesEnabled
      ? isProductionEnv ? stages.EXPERIMENTAL : (isStagingEnv ? stages.PREVIEW : stages.ALPHA)
      : defaultStage
  return {
    isExperimentalFeaturesEnabled,
    systemCustomerStage
  }
})
