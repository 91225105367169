import { BaseIdModel } from '@evelia/common/types'
import { createApi } from '@reduxjs/toolkit/query/react'
import constant from 'lodash/constant'

import { parseValidationErrors } from '../../helpers/apiHelpers'
import { getBaseQuery } from './apiHelpers'
import { basicApiNotification } from './rtkHelpers'
import { ApiResponse } from './types/api'

export type VatCodeModel = {
  vatRateId: number
  vatClassId: number
  isSystem: boolean
  name: string
  eveliaVatCode: string
  fennoaVatCode: string | null
  netvisorVatCode: string | null
  isEnabled: boolean
} & BaseIdModel

export type VatClassModel = {
  name: string
  description: string
  identifier: string
  isReversed: boolean
  isGeneric: boolean
  isVatless: boolean
} & BaseIdModel

export type VatRateModel = {
  rate: number
  vatClassId: number
  validAfter: string
} & BaseIdModel

export type CombinedVatRateModel = {
  name: string
  eveliaVatCode: string
  fennoaVatCode: string | null
  netvisorVatCode: string | null
  vatClassId: number
  isSystem: boolean
  vatClassName: string
  description: string | null
  identifier: string | null
  isReversed: boolean
  isGeneric: boolean
  isVatless: boolean
  vatRateId: number
  rate: number
  validAfter: string
  isDefault: boolean
  isEnabled: boolean
} & BaseIdModel

const transformErrorResponse = baseQueryReturnResponse => {
  const { data, status } = baseQueryReturnResponse as { data: { message?: string, validationErrors: unknown[] }, status: number }
  const error = {
    message: data.message,
    status,
    data,
    json: data,
    validationErrors: parseValidationErrors(data.validationErrors)
  }
  return error
}

export const vatCodeApi = createApi({
  reducerPath: 'vatCodeApi',
  baseQuery: getBaseQuery('vat_codes'),
  tagTypes: ['vat_codes'],
  keepUnusedDataFor: Number.MAX_SAFE_INTEGER, // Never invalidate cache because vatCodes are upserted from whoAmI response. Default is 60 seconds
  endpoints: builder => ({
    getVatCodes: builder.query<ApiResponse<VatCodeModel>, undefined>({
      providesTags: ['vat_codes'],
      query: constant('/')
    }),
    createVatCode: builder.mutation<{ record: VatCodeModel }, Omit<VatCodeModel, 'id'>>({
      query: body => ({
        method: 'POST',
        body,
        url: '/'
      }),
      transformErrorResponse,
      onQueryStarted: async(__args, { dispatch, queryFulfilled }) => {
        const createPromise = async() => {
          const { data: { record } } = await queryFulfilled
          dispatch(vatCodeApi.util.updateQueryData('getVatCodes', undefined, state => {
            state.records = state.records.concat(record)
          }))
        }
        await basicApiNotification(createPromise(),
          {
            successMessage: `Verokoodi päivitetty`,
            errorMessage: `Virhe verokoodin päivityksessä`
          })
      }
    }),
    updateVatCode: builder.mutation<{ record: VatCodeModel }, Omit<VatCodeModel, 'ticketIds'>>({
      query: body => ({
        method: 'PUT',
        body,
        url: `/${body.id}`
      }),
      onQueryStarted: async({ id, ...rest }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(vatCodeApi.util.updateQueryData('getVatCodes', undefined, state => {
          state.records = state.records.map(record => record.id === id ? { ...record, ...rest } : record)
        }))
        await basicApiNotification(queryFulfilled, {
          successMessage: `Verokoodi päivitetty`,
          errorMessage: `Virhe verokoodin päivityksessä`,
          onError: patchResult.undo
        })
      },
      transformErrorResponse
    }),
    deleteVatCode: builder.mutation<{ record: VatCodeModel }, Omit<VatCodeModel, 'ticketIds'>>({
      query: body => ({
        method: 'DELETE',
        body,
        url: `/${body.id}`
      }),
      onQueryStarted: async({ id }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(vatCodeApi.util.updateQueryData('getVatCodes', undefined, state => {
          state.records = state.records.filter(record => record.id !== id)
        }))
        await basicApiNotification(queryFulfilled, {
          successMessage: `Verokoodi poistettu`,
          errorMessage: `Virhe verokoodin poistossa`,
          onError: patchResult.undo
        })
      },
      transformErrorResponse
    })
  })
})

export const {
  useGetVatCodesQuery,
  useCreateVatCodeMutation,
  useUpdateVatCodeMutation,
  useDeleteVatCodeMutation
} = vatCodeApi
