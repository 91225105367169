import { appendEitherOrEmpty } from '@evelia/common/helpers'

import { findAuthorWithId } from '../../selectors/authorSelectors'
import { findContactWithId } from '../../selectors/contactSelectors'
import { findCustomerWithId } from '../../selectors/customerSelectors'
import { findEmployeeWithId } from '../../selectors/employeeSelectors'
import { findMachineWithId } from '../../selectors/machineSelectors'
import { findTargetWithId } from '../../selectors/targetSelectors'
import { findWorkRecordProductWithId } from '../../selectors/workRecordProductSelectors'
import { findWorkWithId } from '../../selectors/workSelectors'
import EmployeeChooser from '../Choosers/EmployeeChooser'
import WorkChooser from '../Choosers/WorkChooser'
import ColorFormInput from '../FormGroups/inputs/ColorFormInput'
import ThemeColorFormInput from '../FormGroups/inputs/ThemeColorFormInput'
import ColorBadge from '../misc/ColorBadge'
import SupplierChooserInput from '../Suppliers/SupplierChooserInput'
import TableLink from './TableLink'

const buildSafeIdSelector = selector => (state, id) => id && selector(state, id)

const findEmployeeWithIdSafe = buildSafeIdSelector(findEmployeeWithId)
const findTargetWithIdSafe = buildSafeIdSelector(findTargetWithId)
const findCustomerWithIdSafe = buildSafeIdSelector(findCustomerWithId)
const findContactWithIdSafe = buildSafeIdSelector(findContactWithId)

export const renderWorkName = placeholder => work => work ? appendEitherOrEmpty(work.workNumber, work.name) : placeholder
const getPersonRenderer = placeholder => person => person ? appendEitherOrEmpty(person.firstName, person.lastName) : placeholder
const getNameRenderer = placeholder => data => data ? data.name : placeholder

const getBasicCell = (selector, renderer) => ({
  selector,
  renderer
})

export const getEmployeeCell = (placeholder = '') =>
  getBasicCell(findEmployeeWithIdSafe, getPersonRenderer(placeholder))

export const getAuthorCell = (placeholder = '') =>
  getBasicCell(findAuthorWithId, author => author?.displayName ?? placeholder)

export const getWorkCell = (placeholder = '') => ({
  selector: findWorkWithId,
  renderer: renderWorkName(placeholder)
})

export const getMachineCell = (placeholder = '') => ({
  selector: findMachineWithId,
  renderer: renderWorkName(placeholder)
})

export const getWorkRecordProductCell = (placeholder = '') => ({
  selector: findWorkRecordProductWithId,
  renderer: workRecordProduct => workRecordProduct ? workRecordProduct.name : placeholder
})

export const getCustomerCell = (placeholder = '') =>
  getBasicCell(findCustomerWithIdSafe, getNameRenderer(placeholder))

export const getContactCell = (placeholder = '') =>
  getBasicCell(findContactWithIdSafe, getPersonRenderer(placeholder))

export const getTargetCell = (placeholder = '') =>
  getBasicCell(findTargetWithIdSafe, getNameRenderer(placeholder))

export const getTableLink = getUrl => (field, model) => <TableLink to={getUrl(model?.id)}>{field}</TableLink>

export const getTableLinkIconCell = getUrl => ({
  header: ' ',
  field: '_link',
  width: 25,
  customRenderer: getTableLink(getUrl)
})

const chooserCellBase = {
  label: null,
  setNull: true,
  positionFixed: true,
  defaultProps: {
    inputOnly: true,
    model: {}
  }
}

export const employeeChooserCell = {
  editingComponent: EmployeeChooser,
  getEditingComponentProps: (row, props) => ({
    ...chooserCellBase,
    employeeId: props?.value,
    updateField: (__fieldName, value) => props.onValueChange(value),
    disabled: !!row.employeeRoleId || row.id
  })
}

export const workChooserCell = {
  editingComponent: WorkChooser,
  getEditingComponentProps: (row, props) => ({
    ...chooserCellBase,
    disabled: !!row.identifier,
    workId: props?.value,
    updateField: (__fieldName, value) => props.onValueChange(value)
  })
}

export const supplierChooserCell = {
  editingComponent: SupplierChooserInput,
  getEditingComponentProps: (__row, props) => ({
    supplierId: props.value,
    labelKey: 'name',
    field: 'supplierId',
    onChange: props.onValueChange,
    setNull: true,
    isTinkaSuppliers: true
  })
}

export const colorChooserCell = {
  header: 'Väri',
  field: 'color',
  customRenderer: value => <ColorBadge color={value} />,
  editingEnabled: true,
  editingComponent: ColorFormInput,
  getEditingComponentProps: () => ({
    model: {},
    setNull: true
  })
}

export const themeColorChooserCell = {
  ...colorChooserCell,
  editingComponent: ThemeColorFormInput
}
