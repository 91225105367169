import { createApi, defaultEmbeddedNormalizer, defaultNormalizer } from '../helpers/apiHelpers'
import { parseFilterParams } from '../helpers/reducerHelpers'
import { optionsQueryBase, optionsQueryTerms, queryTermsToTemplate } from './urlTemplates'

export const timeRecordApi = createApi({
  base: 'time_records',
  normalizer: defaultEmbeddedNormalizer,
  extraUrlTemplate: optionsQueryBase,
  editGetParams: parseFilterParams
})

export const timeRecordTypeApi = createApi({
  base: 'time_record_types',
  normalizer: defaultNormalizer
})

export const timeRecordSiteApi = createApi({
  base: 'time_record_sites',
  normalizer: defaultEmbeddedNormalizer
})

export const timeRecordAccumulationApi = createApi({
  base: 'employees',
  normalizer: defaultEmbeddedNormalizer,
  extraUrlTemplate: queryTermsToTemplate([...optionsQueryTerms, 'type*']),
  editGetParams: ({ employeeId, ...params }) => {
    return parseFilterParams({
      ...params,
      id: employeeId,
      subItem: 'time_record_accumulations'
    })
  },
  editMutateParams: ({ employeeId, ...params }, data) => ({
    ...params,
    id: employeeId,
    subItem: 'time_record_accumulations'
  })
})
