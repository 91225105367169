import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalProps
} from 'reactstrap'
import { RequireExactlyOne } from 'type-fest'

import { callRenderFunctionOrContent, type RenderNode } from '../../helpers/helpers'

type ModalBaseProps = RequireExactlyOne<{
  title: React.ReactNode
  isOpen: boolean
  onCancel?: React.MouseEventHandler
  children?: React.ReactNode
  renderBody?: RenderNode
  renderFooter?: RenderNode
  modalProps?: {
    'data-testid'?: string
  } & ModalProps
}, 'children' | 'renderBody'>

const ModalBase = ({
  title,
  isOpen,
  onCancel,
  renderBody,
  renderFooter,
  modalProps,
  children
}: ModalBaseProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} onClick={ev => ev.stopPropagation() /* Stop propagation, so form submit with enter don't propagate to parent */} {...modalProps}>
      <ModalHeader className='modal-header' cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={onCancel}><span className='modal-title'>{title}</span></ModalHeader>
      <ModalBody>
        {children ?? callRenderFunctionOrContent(renderBody)}
      </ModalBody>
      {renderFooter && (
        <ModalFooter>
          {callRenderFunctionOrContent(renderFooter)}
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ModalBase
