import { FieldErrors } from 'react-hook-form'
import { FormFeedback, InputGroup, InputGroupText } from 'reactstrap'
import { castToArray } from '@evelia/common/helpers'
import clsx from 'clsx'
import get from 'lodash/get'

import { childrenPropType } from '../../propTypes'
import useFloatingLabel from './useFloatingLabel'

interface InputAddonProps {
  isCustomAddon?: boolean
  children?: React.ReactNode
}

// Bootstrap handles button addons differently than other nodes
const InputAddon = ({
  children,
  isCustomAddon
}: InputAddonProps) => {
  if(!children) {
    return null
  }
  return isCustomAddon
    ? children
    : (
      <InputGroupText>
        {children}
      </InputGroupText>
      )
}

InputAddon.propTypes = {
  children: childrenPropType
}

interface RefDivProps {
  innerRef?: (...args: unknown[]) => unknown
}

/**
 * Reactstrap InputGroup and FormFeedback doesn't forward refs..
 */
const RefDiv = ({
  innerRef,
  ...props
}: RefDivProps) => <div ref={innerRef} {...props} />

interface ErrorType {
  message?: string
  msg?: string
}

interface FormInputGroupWrapperProps {
  name: string
  errors?: FieldErrors
  append?: React.ReactNode
  prepend?: React.ReactNode
  children?: React.ReactNode
  tooltipError?: boolean
  isCustomAddon?: boolean
  positionFixed?: boolean
  className?: string
  showErrorsWithDisplayBlock?: boolean
}

export const filterErrorMessages = (error?: ErrorType | ErrorType[] | FieldErrors) => castToArray(error)
  .map(error => error?.message as string)
  .filter(Boolean)

const FormInputGroupWrapper = ({
  append,
  prepend,
  children,
  errors,
  name,
  tooltipError = true,
  isCustomAddon,
  className,
  positionFixed = true,
  // If FormFeedback element is not next to an input, it is display: none. This is a workaround for showing it for e.g. a button group.
  showErrorsWithDisplayBlock
}: FormInputGroupWrapperProps) => {
  const { refs, floatingStyles } = useFloatingLabel(positionFixed)

  const error = get(errors, name)
  const errorMessage = filterErrorMessages(error)
    .map(msg => <span key={msg}>{msg}<br /></span>)
  const feedback = error
    ? (
      <FormFeedback
        innerRef={positionFixed ? refs.setFloating : null}
        style={positionFixed ? floatingStyles : undefined}
        // @ts-expect-error type allows only strings
        tag={RefDiv}
        tooltip={tooltipError}
        className={clsx('white-space-normal', showErrorsWithDisplayBlock && 'd-block')}
      >
        {errorMessage}
      </FormFeedback>
      )
    : null

  if(!append && !prepend) {
    return (
      <span
        ref={(error && positionFixed) ? refs.setReference : null}
      >
        {children}
        {feedback}
      </span>
    )
  }

  return (
    <InputGroup
      size='sm'
      tag={RefDiv}
      innerRef={(error && positionFixed) ? refs.setReference : null}
      className={className}
    >
      <InputAddon isCustomAddon={isCustomAddon}>
        {prepend}
      </InputAddon>
      {children}
      {feedback}
      <InputAddon isCustomAddon={isCustomAddon}>
        {append}
      </InputAddon>
    </InputGroup>
  )
}

export default FormInputGroupWrapper
