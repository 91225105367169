import { useEffect } from 'react'
import { Meta, Row } from '@tanstack/react-table'
import { clsx } from 'clsx'

import { tryGetValue } from '../../helpers/typeHelpers'
import useToggle, { ToggleApi } from '../../hooks/useToggle'
import DataTableCell from './DataTableCell'
import Draggable from './Draggable'
import EditableRowWrapper from './EditableRowWrapper'

type OnRowClick<TData> = (model: TData, row: Row<TData>) => void

export interface CommonCellProps<TData> {
  toggleRowEditingApi: ToggleApi
  isEditable?: boolean
  isDeletable?: boolean
  onRowClick?: OnRowClick<TData>
  useAggregateCells?: boolean
}

interface DataTableRowProps<TData> {
  row: Row<TData>
  enableEditing?: boolean
  isDraggable?: boolean
  meta?: Meta<TData>
  onRowClick?: OnRowClick<TData>
  rowIdField?: string | number | symbol
  isNewRow?: (model: TData) => boolean
}

const DataTableRow = <TData, >({
  row,
  enableEditing,
  meta,
  onRowClick,
  isDraggable = false,
  rowIdField = 'id',
  isNewRow,
  ...cellExtraProps
}: DataTableRowProps<TData>) => {
  // Open new rows (row without id) for editing
  const [isEditable, toggleRowEditingApi] = useToggle(isNewRow?.(row.original) ?? !row.original?.[rowIdField])

  useEffect(() => {
    // Use onCancelEdit e.g. to remove unwanted rows
    if(meta?.onCancelEdit && !isEditable) {
      meta.onCancelEdit(row.original?.[rowIdField])
    } else if(meta?.onEdit && isEditable) {
      meta.onEdit(row.original?.[rowIdField])
    }
  }, [isEditable, meta, row.original, rowIdField])

  const {
    formRowProps,
    isDeletable,
    isEditable: isRowPropsEditable,
    defaultEditValues,
    useAggregateCells,
    ...rowProps
  } = meta?.getRowProps?.(row) || {}

  const onClick = enableEditing
    ? toggleRowEditingApi.enable
    : onRowClick
      ? () => onRowClick(row.original, row)
      : undefined

  const rowClassName = clsx('dt-tr',
    onClick && 'cursor-pointer',
    tryGetValue(rowProps, 'className')
  )
  const isRowEditable = isEditable && isRowPropsEditable !== false
  const commonCellProps = {
    toggleRowEditingApi,
    isEditable: enableEditing && isRowEditable,
    isDeletable: meta?.isDeleteRowsEnabled && (isDeletable == null || isDeletable),
    onRowClick,
    useAggregateCells,
    ...cellExtraProps
  }

  const rowContent = meta?.customRowContent?.(row, commonCellProps, meta) ||
    (
      <>
        {row.getVisibleCells().map(cell => (
          <DataTableCell
            key={cell.id}
            cell={cell}
            commonCellProps={commonCellProps}
          />
        ))}
      </>
    )

  if(enableEditing && isRowEditable) {
    return (
      <EditableRowWrapper
        row={row}
        defaultEditValues={defaultEditValues}
        toggleRowEditingApi={toggleRowEditingApi}
        meta={meta}
        className={rowClassName}
        formRowProps={formRowProps}
        {...rowProps}
      >
        {rowContent}
      </EditableRowWrapper>
    )
  }
  return isDraggable
    ? (
      <Draggable
        id={row.original[rowIdField]}
        role='row'
        onClick={onClick}
        {...rowProps}
        className={rowClassName}
      >
        {rowContent}
      </Draggable>
      )
    : (
      <div
        role='row'
        tabIndex={-1}
        onClick={onClick}
        {...rowProps}
        className={rowClassName}
      >
        {rowContent}
      </div>
      )
}

export default DataTableRow
