import type { ValueOf } from 'type-fest'

export interface TextOption {
  text: string
}

export interface Option<T> extends TextOption {
  value: T
}

export interface OptionWithColor<T> extends Option<T> {
  color: string
}

export type TypeMap<TKey extends Record<string, string | number | symbol>, TValue> = Record<ValueOf<TKey>, TValue>

export const emptyArray = []
Object.freeze(emptyArray)

// Possible to combine with bitwise operations
export const systemFileTags = {
  TAG_TERMS_OF_SERVICE: 2 ** 0,
  TAG_INVOICE_IMAGE: 2 ** 1,
  TAG_WORK_IMAGE: 2 ** 2,
  TAG_INBOUND_INVOICE_IMAGE: 2 ** 3,
  TAG_FINANCE_DOCUMENT: 2 ** 4,
  TAG_RECEIPT: 2 ** 5,
  TAG_CASH_RECEIPT: 2 ** 6,
  TAG_EMPLOYEE_LICENCE: 2 ** 7,
  TAG_SUPPLY_OFFER_IMAGE: 2 ** 8,
  TAG_INBOUND_INVOICE_XML: 2 ** 9,
  TAG_BANKSON_INBOUND: 2 ** 10,
  TAG_BANKSON_OUTBOUND: 2 ** 11,
  TAG_BANKSON_FEEDBACK: 2 ** 12,
  TAG_BANK_STATEMENT: 2 ** 13,
  TAG_SALARY: 2 ** 14,
  TAG_INTEGRATION: 2 ** 15,
  TAG_IMPORT: 2 ** 16,
  TAG_PURCHASE_ORDER: 2 ** 17,
  TAG_INCOMES_REGISTER: 2 ** 18
} as const

export const systemFileTagNames = {
  [systemFileTags.TAG_TERMS_OF_SERVICE]: 'Käyttöehdot',
  [systemFileTags.TAG_INVOICE_IMAGE]: 'Laskun kuva',
  [systemFileTags.TAG_WORK_IMAGE]: 'Työn kuva',
  [systemFileTags.TAG_INBOUND_INVOICE_IMAGE]: 'Ostolaskun kuva',
  [systemFileTags.TAG_FINANCE_DOCUMENT]: 'Kirjanpitodokumentti',
  [systemFileTags.TAG_RECEIPT]: 'Kuitti',
  [systemFileTags.TAG_CASH_RECEIPT]: 'Kassakuitti',
  [systemFileTags.TAG_EMPLOYEE_LICENCE]: 'Pätevyys',
  [systemFileTags.TAG_SUPPLY_OFFER_IMAGE]: 'Tarviketarjouksen kuva',
  [systemFileTags.TAG_INBOUND_INVOICE_XML]: 'Ostolaskun XML',
  [systemFileTags.TAG_BANKSON_INBOUND]: 'Bankson viitteet',
  [systemFileTags.TAG_BANKSON_OUTBOUND]: 'Bankson maksuaineistot',
  [systemFileTags.TAG_BANKSON_FEEDBACK]: 'Bankson palautteet',
  [systemFileTags.TAG_BANK_STATEMENT]: 'Tiliotteet',
  [systemFileTags.TAG_SALARY]: 'Palkat',
  [systemFileTags.TAG_INTEGRATION]: 'Integraatio',
  [systemFileTags.TAG_IMPORT]: 'Tuonti',
  [systemFileTags.TAG_PURCHASE_ORDER]: 'Ostotilaus',
  [systemFileTags.TAG_INCOMES_REGISTER]: 'Tulorekisteri-ilmoitus'
} as const

export const wageIncomeCalculationRules = {
  AMOUNT: 1,
  UNIT_COUNT_AND_PRICE: 2,
  DAY_COUNT: 3
} as const

export const wageIncomeCalculationRuleMap: TypeMap<typeof wageIncomeCalculationRules, string> = {
  [wageIncomeCalculationRules.AMOUNT]: 'Rahamäärä',
  [wageIncomeCalculationRules.UNIT_COUNT_AND_PRICE]: 'Määrä x á-hinta',
  [wageIncomeCalculationRules.DAY_COUNT]: 'Päivien määrä'
} as const

export const wageIncomeGroups = {
  INCOMES: 1,
  FRINGE_BENEFITS: 2,
  VACATIONS: 3,
  TAXFREE_EXPENSES: 4,
  DEDUCTIONS: 5,
  STATISTICS: 6
} as const

export const wageIncomeGroupMap: TypeMap<typeof wageIncomeGroups, string> = {
  [wageIncomeGroups.INCOMES]: 'Palkat ja lisät',
  [wageIncomeGroups.FRINGE_BENEFITS]: 'Luontoisedut',
  [wageIncomeGroups.VACATIONS]: 'Lomat ja poissaolot',
  [wageIncomeGroups.TAXFREE_EXPENSES]: 'Verottomat korvaukset',
  [wageIncomeGroups.DEDUCTIONS]: 'Vähennykset',
  [wageIncomeGroups.STATISTICS]: 'Tilastopalkkalajit'
}

export const salaryReportTypes = {
  excel: 'excel',
  timeRecordExcel: 'timeRecordExcel',
  palkkafi: 'palkkafi',
  personec: 'personec',
  procountor: 'procountor',
  mepco: 'mepco'
} as const

export const cashFlowForecastFieldMap = [
  {
    name: 'Myyntisaatavat',
    field: 'receivablesSum',
    color: '#81cf80',
    chart: 'bar',
    isSumField: false,
    isBudgetField: false,
    isSubField: false,
    detailsField: 'receivablesDetails'
  },
  {
    name: 'Myyntisaatavien erittely',
    field: 'receivablesDetails',
    color: null,
    chart: null,
    isSumField: false,
    isBudgetField: false,
    isSubField: true,
    detailsField: null
  },
  {
    name: 'Muut tulot',
    field: 'otherIncomesSum',
    color: '#5bb0aa',
    chart: 'bar',
    isSumField: false,
    isBudgetField: false,
    isSubField: false,
    detailsField: 'otherIncomesDetails'
  },
  {
    name: 'Muiden tulojen erittely',
    field: 'otherIncomesDetails',
    color: null,
    chart: null,
    isSumField: false,
    isBudgetField: false,
    isSubField: true,
    detailsField: null
  },
  {
    name: 'Ostovelat (ostolaskut)',
    field: 'payablesInboundInvoicesSum',
    color: '#f5574c',
    chart: 'bar',
    isSumField: false,
    isBudgetField: false,
    isSubField: false,
    detailsField: 'payablesInboundInvoicesDetails'
  },
  {
    name: 'Ostovelkojen erittely',
    field: 'payablesInboundInvoicesDetails',
    color: null,
    chart: null,
    isSumField: false,
    isBudgetField: false,
    isSubField: true,
    detailsField: null
  },
  {
    name: 'Ostovelat (maksut)',
    field: 'payablesPaymentsSum',
    color: '#ad5ca9',
    chart: 'bar',
    isSumField: false,
    isBudgetField: false,
    isSubField: false,
    detailsField: 'payablesPaymentsDetails'
  },
  {
    name: 'Ostovelkojen (maksut) erittely',
    field: 'payablesPaymentsDetails',
    color: null,
    chart: null,
    isSumField: false,
    isBudgetField: false,
    isSubField: true,
    detailsField: null
  },
  {
    name: 'Muut kulut',
    field: 'otherExpensesSum',
    color: '#e88f89',
    chart: 'bar',
    isSumField: false,
    isBudgetField: false,
    isSubField: false,
    detailsField: 'otherExpencesDetails'
  },
  {
    name: 'Muiden kulujen erittely',
    field: 'otherExpencesDetails',
    color: null,
    chart: null,
    isSumField: false,
    isBudgetField: false,
    isSubField: true,
    detailsField: null
  },
  {
    name: 'Palkat',
    field: 'salariesSum',
    color: '#faae57',
    chart: 'bar',
    isSumField: false,
    isBudgetField: false,
    isSubField: false,
    detailsField: 'salariesDetails'
  },
  {
    name: 'Palkkojen erittely',
    field: 'salariesDetails',
    color: null,
    chart: null,
    isSumField: false,
    isBudgetField: false,
    isSubField: true,
    detailsField: null
  },
  {
    name: 'Pankkitilisaldo',
    field: 'bankAccountBalance',
    color: '#2f2f30',
    chart: 'line',
    isSumField: true,
    isBudgetField: false,
    isSubField: false,
    detailsField: null
  },
  {
    name: 'Budjetissa olevat ostovaraukset',
    field: 'costProvisionsSum',
    color: '#86768c',
    chart: 'bar',
    isSumField: false,
    isBudgetField: true,
    isSubField: false,
    detailsField: 'costProvisionsDetails'
  },
  {
    name: 'Budjetissa olevien ostovarausten erittely',
    field: 'costProvisionsDetails',
    color: null,
    chart: null,
    isSumField: false,
    isBudgetField: false,
    isSubField: true,
    detailsField: null
  },
  {
    name: 'Budjetissa oleva laskutusarvio',
    field: 'incomeProvisionsSum',
    color: '#7070db',
    chart: 'bar',
    isSumField: false,
    isBudgetField: true,
    isSubField: false,
    detailsField: 'incomeProvisionsDetails'
  },
  {
    name: 'Budjetissa olevan laskutusarvioiden erittely',
    field: 'incomeProvisionsDetails',
    color: null,
    chart: null,
    isSumField: false,
    isBudgetField: false,
    isSubField: true,
    detailsField: null
  },
  {
    name: 'Pankkitilisaldo budjetoitujen kanssa',
    field: 'bankAccountBalanceWithBudget',
    color: '#d8db1f',
    chart: 'line',
    isSumField: true,
    isBudgetField: false,
    isSubField: false,
    detailsField: null
  }
]

export const cashFlowForecastExtraValueTypes = {
  INCOME: 'income',
  EXPENSE: 'expense',
  SALARY: 'salary'
} as const

export const cashFlowForecastExtraValueTypesMap: TypeMap<typeof cashFlowForecastExtraValueTypes, TextOption> = {
  [cashFlowForecastExtraValueTypes.INCOME]: { text: 'Tulo' },
  [cashFlowForecastExtraValueTypes.EXPENSE]: { text: 'Kulu' },
  [cashFlowForecastExtraValueTypes.SALARY]: { text: 'Palkka' }
}

export const cashFlowForecastViews = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
} as const

export const cashFlowForecastViewsMap: TypeMap<typeof cashFlowForecastViews, TextOption & { tableText: string }> = {
  [cashFlowForecastViews.DAY]: { text: 'Päivänäkymä', tableText: 'Päivä' },
  [cashFlowForecastViews.WEEK]: { text: 'Viikkonäkymä', tableText: 'Viikko' },
  [cashFlowForecastViews.MONTH]: { text: 'Kuukausinäkymä', tableText: 'Kuukausi' }
}

export const cashFlowForecastDateType = {
  CASH_FLOW_FORECAST_OVERDUE_DATE: 'overdue'
} as const

export const invoicingTypeParams = {
  EMAIL: 'email',
  FACTORING: 'factoring',
  MAVENTA: 'maventa',
  PDF: 'pdf',
  XML: 'xml'
} as const

export const choosableInvoicingTypes = [invoicingTypeParams.MAVENTA, invoicingTypeParams.FACTORING, invoicingTypeParams.EMAIL, invoicingTypeParams.PDF]

export const factoringProviderIds = {
  NOT_ENABLED: -1,
  FACTORING_NOJA: 1,
  FACTORING_CERTUM: 2
} as const

export const CERTUM_PROCESS_TYPES = {
  FACTORING: 'factoring',
  DEFAULT: 'default'
} as const

export const formTypeFooterKeywords = {
  systemCustomerName: 'yrityksen nimi',
  systemCustomerEmail: 'yrityksen email',
  systemCustomerPhoneNumber: 'yrityksen puhelinnumero',
  systemCustomerAddress: 'yrityksen osoite',
  systemCustomerPostalCode: 'yrityksen postinumero',
  systemCustomerCity: 'yrityksen postitoimipaikka',
  systemCustomerWebsite: 'yrityksen verkkosivu',
  systemCustomerBusinessId: 'yrityksen y-tunnus'
}as const

export const EVELIA_TOTP_TOKEN_HEADER = 'X-Evelia-Totp-Token'
export const EVELIA_WORK_HEADER = 'X-Evelia-Work-Version'

export const mfaErrorResponses = {
  setupRequired: {
    message: 'Lisävarmenteen käyttöönotto vaaditaan',
    errorCode: 'setupRequired'
  },
  required: {
    message: 'Lisävarmenne vaaditaan',
    errorCode: 'required'
  },
  invalid: {
    message: 'Tunnusta ei ole olemassa tai sitä ei ole kytketty mihinkään yritykseen. Kirjaudu sisään Evelian käyttäjätunnuksellasi',
    errorCode: 'invalid'
  }
}

export const mfaResponses = {
  setupRequired: { mfaStatus: 'setupRequired' },
  required: { mfaStatus: 'required' },
  invalid: { mfaStatus: 'invalid' }
}

export const mfaStatusOk = 'valid'

export const mfaRequireTypes = {
  // ToDO hidden fields are not ready to be used in Evelia Work
  ALL: {
    text: 'Kaikille',
    value: 1,
    hidden: true
  },
  ALL_NOT_WORK: {
    text: 'Kaikille (selaimessa)',
    value: 2
  },
  SUPERVISOR_LEVEL_OR_ABOVE: {
    text: 'Työnjohtajatasosta ylöspäin',
    value: 3,
    hidden: true
  },
  SUPERVISOR_LEVEL_OR_ABOVE_NOT_WORK: {
    text: 'Työnjohtajatasosta ylöspäin (selaimessa)',
    value: 4
  },
  NOT_IN_USE: {
    text: 'Poissa käytöstä',
    value: null
  }
}

export const timeRecordSystemTypes = {
  TIME_RECORD_SYSTEM_TYPE_AWAY: 0,
  TIME_RECORD_SYSTEM_TYPE_WORK_TIME: 1,
  TIME_RECORD_SYSTEM_TYPE_LUNCH: 2,
  TIME_RECORD_SYSTEM_TYPE_TRAINING: 3,
  TIME_RECORD_SYSTEM_TYPE_CUSTOMER: 4,
  TIME_RECORD_SYSTEM_TYPE_SICK_LEAVE: 5,
  TIME_RECORD_SYSTEM_TYPE_VACATION_WITH_SALARY: 6,
  TIME_RECORD_SYSTEM_TYPE_EXTRA_LEAVE: 7,
  TIME_RECORD_SYSTEM_TYPE_STAND_BY: 8,
  TIME_RECORD_SYSTEM_TYPE_ON_CALL: 9,
  TIME_RECORD_SYSTEM_TYPE_TRAVEL_TIME: 10,
  TIME_RECORD_SYSTEM_TYPE_VACATION_WITHOUT_SALARY: 11,
  TIME_RECORD_SYSTEM_TYPE_ANNUAL_LEAVE: 12,
  TIME_RECORD_SYSTEM_TYPE_FLEXITIME: 13,
  TIME_RECORD_SYSTEM_TYPE_SICK_LEAVE_OCCUPATIONAL_ACCIDENT: 14,
  TIME_RECORD_SYSTEM_TYPE_SICK_LEAVE_CHILD_SICK: 15
} as const

export const inboundInvoiceTypes = {
  INBOUND_INVOICE_TYPE_INVOICE: 'invoice',
  INBOUND_INVOICE_TYPE_PAYMENT: 'payment',
  INBOUND_INVOICE_TYPE_SALARY: 'salary',
  INBOUND_INVOICE_TYPE_CREDIT: 'credit',
  INBOUND_INVOICE_TYPE_TEMPLATE: 'template'
} as const

export const inboundInvoiceCostTypes = {
  INBOUND_INVOICE_COST_TYPE_DEFAULT: 'default',
  INBOUND_INVOICE_COST_TYPE_SUBCONTRACTING: 'subcontracting'
} as const

export const inboundInvoiceStates = {
  INBOUND_INVOICE_STATE_RECEIVED: 'received',
  INBOUND_INVOICE_STATE_RECEIVED_FULL: 'received_full',
  INBOUND_INVOICE_STATE_WAITING_APPROVAL: 'waiting_approval',
  INBOUND_INVOICE_STATE_APPROVED: 'approved',
  INBOUND_INVOICE_STATE_REJECTED: 'rejected'
} as const

export const inboundInvoicePaymentStates = {
  INBOUND_INVOICE_PAYMENT_STATE_PAID: 'paid',
  INBOUND_INVOICE_PAYMENT_STATE_PAID_MANUALLY: 'paid_manually',
  INBOUND_INVOICE_PAYMENT_STATE_PARTIALLY_PAID: 'partially_paid',
  INBOUND_INVOICE_PAYMENT_STATE_OVERPAID: 'overpaid',
  INBOUND_INVOICE_PAYMENT_STATE_WAITING: 'waiting',
  INBOUND_INVOICE_PAYMENT_STATE_WAITING_PERMISSION: 'waiting_permission',
  INBOUND_INVOICE_PAYMENT_STATE_FAILED: 'failed'
} as const

export const inboundInvoiceAccountingStates = {
  INBOUND_INVOICE_ACCOUNTING_STATE_WAITING: 'waiting',
  INBOUND_INVOICE_ACCOUNTING_STATE_PARTIALLY: 'partially',
  INBOUND_INVOICE_ACCOUNTING_STATE_DONE: 'done'
} as const

export const inboundInvoiceBanksonStates = {
  INBOUND_INVOICE_BANKSON_STATE_MANUAL: 'manual',
  INBOUND_INVOICE_BANKSON_STATE_SUCCESS: 'success',
  INBOUND_INVOICE_BANKSON_STATE_SENT: 'sent',
  INBOUND_INVOICE_BANKSON_STATE_SENT_WITH_ERROR: 'sent_with_error',
  INBOUND_INVOICE_BANKSON_STATE_FAILED: 'failed'
} as const

export const INBOUND_INVOICE_TYPES_PAYMENTLIKE_PLAIN = [inboundInvoiceTypes.INBOUND_INVOICE_TYPE_PAYMENT, inboundInvoiceTypes.INBOUND_INVOICE_TYPE_SALARY]

export const INBOUND_INVOICE_TYPES_PAYMENTLIKE = [...INBOUND_INVOICE_TYPES_PAYMENTLIKE_PLAIN, inboundInvoiceTypes.INBOUND_INVOICE_TYPE_TEMPLATE]

export const projectAdditionalEmployeeTypes = {
  PROJECT_ADDITIONAL_EMPLOYEE_TYPE_NORMAL_EMPLOYEE: 1,
  PROJECT_ADDITIONAL_EMPLOYEE_TYPE_RENTAL_EMPLOYEE: 2,
  PROJECT_ADDITIONAL_EMPLOYEE_TYPE_INDEPENDENT_EMPLOYEE: 3
} as const

export const timeRecordAccumulationTypes = {
  TIME_RECORD_ACCUMULATION_TYPE_FLEXITIME: 0,
  TIME_RECORD_ACCUMULATION_TYPE_OVERTIME: 1,
  TIME_RECORD_ACCUMULATION_TYPE_VACATION_EARNING: 2,
  TIME_RECORD_ACCUMULATION_TYPE_VACATION_USAGE: 3,
  TIME_RECORD_ACCUMULATION_TYPE_EXTRA_LEAVE_EARNING: 4,
  TIME_RECORD_ACCUMULATION_TYPE_EXTRA_LEAVE_USAGE: 5
} as const

export const timeRecordAccumulationUnits = {
  UNIT_HOURS: 'hours',
  UNIT_DAYS: 'days'
} as const

export const timeRecordAccumulationUnitNames = {
  [timeRecordAccumulationUnits.UNIT_DAYS]: 'pv',
  [timeRecordAccumulationUnits.UNIT_HOURS]: 'h'
}

export const timeRecordAccumulationTypesMap: TypeMap<typeof timeRecordAccumulationTypes, { name: string, negativeColor: string, positiveColor: string, unit: ValueOf<typeof timeRecordAccumulationUnits>, coefficient: number }> = {
  [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_FLEXITIME]: {
    name: 'Liukuma',
    negativeColor: 'danger',
    positiveColor: 'success',
    unit: timeRecordAccumulationUnits.UNIT_HOURS,
    coefficient: 1
  },
  [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_OVERTIME]: {
    name: 'Ylityö',
    negativeColor: 'warning',
    positiveColor: 'warning',
    unit: timeRecordAccumulationUnits.UNIT_HOURS,
    coefficient: 1
  },
  [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_VACATION_EARNING]: {
    name: 'Vuosiloma (kertymä)',
    negativeColor: 'info',
    positiveColor: 'info',
    unit: timeRecordAccumulationUnits.UNIT_DAYS,
    coefficient: 1
  },
  [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_VACATION_USAGE]: {
    name: 'Vuosiloma (käyttö)',
    negativeColor: 'info',
    positiveColor: 'info',
    unit: timeRecordAccumulationUnits.UNIT_DAYS,
    coefficient: -1
  },
  [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_EXTRA_LEAVE_EARNING]: {
    name: 'Pekkasvapaa (kertymä)',
    negativeColor: 'info',
    positiveColor: 'info',
    unit: timeRecordAccumulationUnits.UNIT_DAYS,
    coefficient: 1
  },
  [timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_EXTRA_LEAVE_USAGE]: {
    name: 'Pekkasvapaa (käyttö)',
    negativeColor: 'info',
    positiveColor: 'info',
    unit: timeRecordAccumulationUnits.UNIT_DAYS,
    coefficient: -1
  }
}

export const timeRecordAccumulationSources = {
  TIME_RECORD_ACCUMULATION_SOURCE_TIME_RECORD: 'timeRecord',
  TIME_RECORD_ACCUMULATION_SOURCE_MANUAL: 'manual',
  TIME_RECORD_ACCUMULATION_SOURCE_CRONJOB: 'cronjob',
  TIME_RECORD_ACCUMULATION_SOURCE_SALARY_CALCULATION: 'salaryCalculation'
} as const

export const offerPostRowImportSource = {
  JCAD_S: 'jcad_s',
  JCAD_MIXED: 'jcad_mixed',
  EXCEL: 'excel'
} as const

export const EMOJI_SOURCE_CDN = 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/'

export const EVELIA_VERSION_SOCKET_KEY = 'evelia:version'

export const fileRelationTables = {
  WORK: 'work',
  INVOICE: 'invoice',
  INBOUND_INVOICE: 'inbound_invoice',
  TARGET: 'target',
  SUPPLY_OFFER: 'supply_offer',
  TICKET: 'ticket',
  CONTACT: 'contact',
  CUSTOMER: 'customer',
  CUSTOMER_CONTACT: 'customer_contact',
  PROJECT: 'project',
  INSTALMENT: 'instalment',
  PROJECT_REFERRAL: 'project_referral'
} as const

export const fileRelationTableDescriptions: TypeMap<typeof fileRelationTables, string> = {
  [fileRelationTables.WORK]: 'Työt',
  [fileRelationTables.INVOICE]: 'Laskut',
  [fileRelationTables.INBOUND_INVOICE]: 'Ostolaskut',
  [fileRelationTables.TARGET]: 'Kohteet',
  [fileRelationTables.SUPPLY_OFFER]: 'Tarviketarjoukset',
  [fileRelationTables.TICKET]: 'Tehtävät',
  [fileRelationTables.CONTACT]: 'Yhteyshenkilöt',
  [fileRelationTables.CUSTOMER]: 'Asiakkaat',
  [fileRelationTables.CUSTOMER_CONTACT]: 'Yhteyshenkilöt',
  [fileRelationTables.PROJECT]: 'Projektit',
  [fileRelationTables.INSTALMENT]: 'Maksuerät',
  [fileRelationTables.PROJECT_REFERRAL]: 'Projektilähete'
}

export const tableAbbreviations: TypeMap<typeof fileRelationTables, string> = {
  [fileRelationTables.WORK]: 'w',
  [fileRelationTables.INVOICE]: 'i',
  [fileRelationTables.INBOUND_INVOICE]: 'ii',
  [fileRelationTables.TARGET]: 'ta',
  [fileRelationTables.SUPPLY_OFFER]: 'so',
  [fileRelationTables.TICKET]: 'ti',
  [fileRelationTables.CONTACT]: 'co',
  [fileRelationTables.CUSTOMER]: 'cu',
  [fileRelationTables.CUSTOMER_CONTACT]: 'cco',
  [fileRelationTables.PROJECT]: 'p',
  [fileRelationTables.INSTALMENT]: 'inst',
  [fileRelationTables.PROJECT_REFERRAL]: 'pr'
}

export const insuranceCompanyTypes = {
  INSURANCE_COMPANY_TYPE_ACCIDENT: 0,
  INSURANCE_COMPANY_TYPE_PENSION: 1
} as const

export const projectStates = {
  PROJECT_STATE_CREATED: 'created',
  PROJECT_STATE_STARTED: 'started',
  PROJECT_STATE_CLOSED: 'closed'
} as const

export const projectStateMap: TypeMap<typeof projectStates, TextOption & { color: string }> = {
  [projectStates.PROJECT_STATE_CREATED]: {
    text: 'Uusi',
    color: 'warning'
  },
  [projectStates.PROJECT_STATE_STARTED]: {
    text: 'Käynnissä',
    color: 'success'
  },
  [projectStates.PROJECT_STATE_CLOSED]: {
    text: 'Päättynyt',
    color: 'info'
  }
}

export const purchaseOrderRowTypes = {
  PURCHASE_ORDER_ROW_TYPE_PRODUCT: 'product',
  PURCHASE_ORDER_ROW_TYPE_COMMENT: 'comment'
} as const

export const ticketTemplateListTypes = {
  TEMPLATE_TICKET_LIST_WORK: 'work'
} as const

export const ticketTemplateListTypeOptions = [
  {
    value: 'work',
    text: 'Lähete',
    color: 'primary'
  }
]

export const cashReceiptEmailTemplateVariables = {
  CASH_RECEIPT_EMAIL_TEMPLATE_CASH_RECEIPT_NUMBER: 'kassakuitin numero',
  CASH_RECEIPT_EMAIL_TEMPLATE_SYSTEM_CUSTOMER_NAME: 'yrityksen nimi'
} as const

export const cashReceiptEmailDefaultSettings = {
  subject: `Kassakuitti: [${cashReceiptEmailTemplateVariables.CASH_RECEIPT_EMAIL_TEMPLATE_CASH_RECEIPT_NUMBER}]`,
  message: `Kuitti liitteenä pdf-muodossa\n\nTerveisin:\n[${cashReceiptEmailTemplateVariables.CASH_RECEIPT_EMAIL_TEMPLATE_SYSTEM_CUSTOMER_NAME}]`
} as const

export const salaryCalculationRowTypes = {
  SALARY_CALCULATION_ROW_TYPE_TAX_DEDUCTION: 10,
  SALARY_CALCULATION_ROW_TYPE_PENSION: 20,
  SALARY_CALCULATION_ROW_TYPE_UNEMPLOYMENT: 30
} as const

export const salaryPeriodTypes = {
  SALARY_PERIOD_TYPE_MONTH: 0,
  SALARY_PERIOD_TYPE_TWICE_PER_MONTH: 1,
  SALARY_PERIOD_TYPE_WEEK: 2,
  SALARY_PERIOD_TYPE_2_WEEKS: 3,
  SALARY_PERIOD_TYPE_3_WEEKS: 4,
  SALARY_PERIOD_TYPE_4_WEEKS: 5
} as const

export const salaryPeriodTypeMap: TypeMap<typeof salaryPeriodTypes, TextOption> = {
  [salaryPeriodTypes.SALARY_PERIOD_TYPE_MONTH]: {
    text: 'Kuukausi'
  },
  [salaryPeriodTypes.SALARY_PERIOD_TYPE_TWICE_PER_MONTH]: {
    text: 'Kaksi kertaa kuukaudessa'
  },
  [salaryPeriodTypes.SALARY_PERIOD_TYPE_WEEK]: {
    text: '1 viikko'
  },
  [salaryPeriodTypes.SALARY_PERIOD_TYPE_2_WEEKS]: {
    text: '2 viikkoa'
  },
  [salaryPeriodTypes.SALARY_PERIOD_TYPE_3_WEEKS]: {
    text: '3 viikkoa'
  },
  [salaryPeriodTypes.SALARY_PERIOD_TYPE_4_WEEKS]: {
    text: '4 viikkoa'
  }
} as const

export const salaryPeriodOptions = Object.entries(salaryPeriodTypeMap).map(([type, data]) => ({ ...data, value: +type }))

export const paymentDateOffsetBases = {
  START: 'start',
  END: 'end'
} as const

export const salaryCalculationStates = {
  SALARY_CALCULATION_IN_PROGRESS: 'inProgress',
  SALARY_CALCULATION_READY: 'ready',
  SALARY_CALCULATION_PAID: 'paid',
  SALARY_CALCULATION_CANCELED: 'canceled'
} as const

export const offerVatTypes = {
  OFFER_VAT_TYPE_DEFAULT: 0,
  OFFER_VAT_TYPE_VATLESS: 1,
  OFFER_VAT_TYPE_REVERSED: 2
} as const

export const denominationSystemTypes = {
  DENOMINATION_TYPE_DEFAULT: 'default',
  DENOMINATION_TYPE_LVIS: 'lvis'
} as const

export const packetSources = {
  SOURCE_STUL: 'stul',
  SOURCE_LVI: 'lvi',
  SOURCE_LVI_PACKETS: 'lviPackets',
  SOURCE_LVI_OFFERS: 'offerPackets',
  SOURCE_OWN: 'own'
} as const

export const workRowTypes = {
  WORK_ROW_TYPE_PRODUCT: 'product',
  WORK_ROW_TYPE_COMMENT: 'comment',
  WORK_ROW_TYPE_WORK_RECORD: 'workRecord',
  WORK_ROW_TYPE_MACHINE_RECORD: 'machineRecord',
  WORK_ROW_TYPE_INBOUND_INVOICE: 'inboundInvoice',
  WORK_ROW_TYPE_TEMPLATE: 'template',
  WORK_ROW_TYPE_FIXED_PRICE: 'fixedPrice',
  WORK_ROW_TYPE_SUB_TOTAL: 'subTotal',
  WORK_ROW_TYPE_SUB_HEADER: 'subHeader',
  WORK_ROW_TYPE_INSTALMENT: 'instalment'
} as const

export const nonPriceWorkRowTypes = [
  workRowTypes.WORK_ROW_TYPE_COMMENT,
  workRowTypes.WORK_ROW_TYPE_SUB_TOTAL,
  workRowTypes.WORK_ROW_TYPE_SUB_HEADER
]

export const workRowStates = {
  WORK_ROW_STATE_OPEN: null,
  WORK_ROW_STATE_APPROVED: 'approved',
  WORK_ROW_STATE_REJECTED: 'rejected',
  WORK_ROW_STATE_CONFIRMATION_REQUESTED: 'confirmation_requested'
} as const

export const purchaseOrderStates = {
  PURCHASE_ORDER_STATE_OPEN: 0,
  PURCHASE_ORDER_STATE_SENT: 1,
  PURCHASE_ORDER_STATE_CONFIRMED: 2,
  PURCHASE_ORDER_STATE_DELIVERED: 3,
  PURCHASE_ORDER_STATE_ARRIVED: 4,
  PURCHASE_ORDER_STATE_CANCELLED: 5
} as const

export const purchaseOrderStateMap = {
  [purchaseOrderStates.PURCHASE_ORDER_STATE_OPEN]: {
    text: 'Avoin',
    color: 'warning'
  },
  [purchaseOrderStates.PURCHASE_ORDER_STATE_SENT]: {
    text: 'Lähetetty',
    color: 'info'
  },
  [purchaseOrderStates.PURCHASE_ORDER_STATE_CONFIRMED]: {
    text: 'Vahvistettu',
    color: 'info'
  },
  [purchaseOrderStates.PURCHASE_ORDER_STATE_DELIVERED]: {
    text: 'Toimitettu',
    color: 'success'
  },
  [purchaseOrderStates.PURCHASE_ORDER_STATE_ARRIVED]: {
    text: 'Saapunut',
    color: 'success'
  },
  [purchaseOrderStates.PURCHASE_ORDER_STATE_CANCELLED]: {
    text: 'Peruutettu',
    color: 'danger'
  }
} as Record<ValueOf<typeof purchaseOrderStates>, { text: string, color: string }>

export const vatTypes = {
  reversedVat: 'reversedVat',
  euSales: 'euSales',
  normal: null
} as const

export const certificateTypes = {
  VERO_API: 1
} as const

export const systemMessageTypes = {
  SMS: 'sms',
  EMAIL: 'email',
  NOTIFICATION: 'notification'
} as const

export const genericSendStatuses = {
  WAITING: 'waiting',
  COMPLETED: 'completed',
  ERROR: 'error'
} as const

export const vacationAccumulationRightRules = {
  VACATION_ACCUMULATION_DAYS: 0,
  VACATION_ACCUMULATION_HOURS: 1,
  NO_VACATION_ACCUMULATION: 2
} as const

export const systemCustomerAnnualInvoicingTypes = {
  INVOICE: 'invoice',
  INBOUND_INVOICE: 'inboundInvoice'
} as const
